import React from 'react';
import {Box,Stack} from '@mui/material'
import ConversionButtonA from "../../Components/ConversionButtonA";
import ConversionButtonB from "../../Components/ConversionButtonB";

function TopSection17(){

    return (
        <Box component="section" backgroundColor="#4771DF" textAlign="center" pt={10} pb={10}>
                <Box className='section-content' textAlign="center" color="white">
                    <Box component="h1" fontSize={{xs:30, md:45}} fontWeight="bold">助成金、<Box component="br" display={{xs:"block",md:"none"}}/><Box component="span" fontSize={{xs:20, md:30}}>どうせやるなら</Box>「フル活用」！</Box>

                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" mt={10} gap={5}>
                        
                        <Stack flex={1} alignItems="center" gap={2}>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA1_2.png"} alt="CTA1_2" width="100%" maxWidth={400}></Box>

                            <ConversionButtonA display="block" fontSize={{xs:20, md:30}} >無料<Box component="span" margin="0 4px" fontSize={{xs:16, md:20}}>で</Box>助成金診断<Box component="span" margin="0 4px" fontSize={{xs:16, md:20}}>する</Box></ConversionButtonA>
                        </Stack>
                        
                        <Stack flex={1} alignItems="center" gap={2}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA2_2.png"} alt="CTA2_2" width="100%" maxWidth={400}></Box>
                            <ConversionButtonB display="block" fontSize={{xs:20, md:30}} >無料<Box component="span" margin="0 4px"  fontSize={{xs:16, md:20}}>で</Box>プロに相談<Box component="span" margin="0 4px" fontSize={{xs:16, md:20}}>する</Box></ConversionButtonB>
                        </Stack>
                        
                    </Stack>
                </Box>
            </Box>
    )
}

export default TopSection17;