import React from 'react';
import {Box,Stack} from '@mui/material'

function TopSection2(){

    return (
        <Box component="section" backgroundColor="#EBEBEB" pt={10} pb={10}>
            <Box className='section-content'>

                <Stack fontSize={30} flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" alignItems="center" gap={3}>
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_award1.png"} alt="TOP_award1" width="100%" maxWidth={250}></Box>
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_award2.png"} alt="TOP_award2" width="100%" maxWidth={250}></Box>
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_award3.png"} alt="TOP_award3" width="100%" maxWidth={250}></Box>
                </Stack>
                <Box fontSize={11} mt={5}>
                    <Box>※1 2024年3月現在</Box>
                    <Box>※2 日本HRチャレンジ大賞は、助成金コーディネートの元となるサービスである「クラウド社労士コモン」が受賞しました。</Box>
                    <Box>※3 厚生労働省『輝くテレワーク賞』特別奨励賞に受賞、総務省『テレワーク先駆者百選』に選出、東京都『東京ライフワークバランス認定企業』に認定、東京都『東京都女性活躍推進大賞』優秀賞を受賞、など</Box>
                </Box>
            </Box>
        </Box>
    )
}

export default TopSection2;