import React,{ useState } from 'react';
import {Box,Stack} from '@mui/material'
import ConversionButtonA from "../../Components/ConversionButtonA";
import ConversionButtonB from "../../Components/ConversionButtonB";
import Header from './Header';
import Footer from './Footer';

function Faq(){

    const [faqSubsidyList,setFaqSubsidyList] = useState([
        {isOpen:false, q:"助成金と補助金は、同じ制度ですか？", a:
            <>
                <Box>助成金と補助金は全く異なる、国の金銭的な支援制度です。</Box>
                <Box>助成金は厚生労働省管轄で、従業員に関わる支援制度です。</Box>
                <Box>補助金は主には経済産業省管轄で、事業に関わる支援制度です。</Box>
                <Box>自社の状況や計画に合わせて２つの制度を使い分けます。</Box>
            </>},
        {isOpen:false, q:"もらった助成金は返済する必要はありますか？", a:"いいえ、助成金は返済する必要はありません。"},
        {isOpen:false, q:"助成金は一回受け取ったら終わりですか？", a:
            <>
                <Box>助成金は、対象者が出る度に申請できる助成金と、１社１回しか受け取れない助成金と、大きく２種類あります。</Box>
                <Box>ただし、大半の助成金は対象者が出る度に申請できる種類です。</Box>
                <Box>また年度が終わる際に時代に合わない助成金は終了し、年度が変わると新たな助成金が発表されます。</Box>
                <Box>一回受け取ったら終わりではなく、自社の状況に合わせて毎年助成金をフル活用することができます。</Box>
            </>},
        {isOpen:false, q:"目的の用途と違う使い方をすることはできますか？", a:
            <>
                <Box>受け取った助成金は、使用用途が自由です。</Box>
                <Box>会社経営の何にお使いいただいても構いませんので、採用経費を掛ける、宣伝広告費に使う、出産祝い金で還元する等、会社経営のあらゆる予算に回していただけます。</Box>
            </>},
        {isOpen:false, q:"助成金を受け取った後に何か報告する必要はありますか？", a:"いいえ、助成金を受け取った後の事後的な報告は原則不要です。"},
        {isOpen:false, q:"社労士だったら誰でも助成金申請できますか？", a:
            <>
                <Box>いいえ、社労士と一言で言いましても専門分野が分かれています。</Box>
                <Box>労務相談が専門の社労士、給与計算が専門の社労士、労務監査が専門の社労士など。</Box>
                <Box>その中で社労士全体のわずか1％、助成金が専門の社労士がいます。</Box>
            </>
        },
        {isOpen:false, q:"助成金についてはどこで調べれることができますか？", a:
            <>
                <Box>厚生労働省のホームページに、助成金に関する全ての情報が掲載されています。</Box>
                <Box>毎年50～60種類発表される助成金1つ1つ毎に100ページほどのマニュアルが用意されており、ホームページ上で確認していただくことができます。</Box>
            </>
        },
    ])

    const [faqJcoordinateList,setFaqJcoordinateList] = useState([
        {isOpen:false, q:"個人事業主でもサービスを利用することはできますか？", a:
            <>
                <Box>はい、ご利用できます。</Box>
                <Box>雇用保険に加入している従業員がいれば、法人・個人に関係なく助成金申請の対象となりますので、サービスをご利用できます。</Box>
                <Box>また、NPO法人、医療法人、一般社団法人、有限会社なども同様にご利用できます。</Box>
            </>},
        {isOpen:false, q:"契約期間の縛りはありますか？", a:
            <>
                <Box>月額プランをお選びいただきますと、契約期間の縛りはありません。</Box>
                <Box>ご契約の単位は1ヶ月単位で、1ヶ月毎の自動更新となります。</Box>
                <Box>例）ご契約日が「5月25日」の場合、「5月25日～6月24日」が契約期間（サービス提供期間）となります。</Box>
            </>},
        {isOpen:false, q:"お支払い方法を教えてください。", a:"お支払方法は、クレジットカード払い・銀行引き落とし・銀行振り込みからお選びいただけます。"},
        {isOpen:false, q:"複数社で1つの申し込みで良いですか？", a:"助成金は会社毎に申請するため、各会社様毎にお申し込みいただく必要がございます。"},
        {isOpen:false, q:"助成金だけでなく、補助金も対応していただけますか？", a:
            <>
                <Box>はい。経済産業省管轄の補助金（下記）につきましても、TRIPORTにて申請サポートをしております。</Box>
                <Box>・事業再構築補助金</Box>
                <Box>・ものづくり補助金</Box>
                <Box>・IT導入補助金（ベンダー登録・ツール登録も含む）</Box>
                <Box>・小規模事業者持続化補助金</Box>
                <Box>厚生労働省管轄の助成金も経済産業省管轄の補助金も、お気軽にご相談ください。</Box>
            </>},
        {isOpen:false, q:"解約する方法は？", a:
            <>
                <Box>貴社専属のチームの担当者へ解約のご連絡をお願いします。</Box>
                <Box>契約更新日の2週間前までにご連絡いただきましたら、契約更新日に解約となります。</Box>
            </>
        },
        {isOpen:false, q:"会社が地方にありますが、対応してもらえますか？", a:
            <>
                <Box>はい、全国どこでも対応可能です。</Box>
                <Box>助成金は郵送やオンラインでの申請となりますので、インターネットの環境がありましたらサービスを受けることができます。</Box>
                <Box>現在弊社のお客様は全47都道府県に所在しており、離島にもいらっしゃいます。</Box>
            </>
        },
        {isOpen:false, q:"労務の知識が全くありません。そのサポートはしてもらえますか？", a:
            <>
                <Box>全く問題ございません。</Box>
                <Box>労働法令における義務や注意点など一からサポートしております。</Box>
            </>
        },
    ])

    return (
        <>
            <Header/>

            <Box component="section" backgroundColor="#D1EAFE" pt={30} pb={20}>
                <Box className='section-content' >
                    <Box textAlign="center" fontSize={{xs:20, md:30}} fontWeight="bold">よくあるご質問</Box>
                </Box>
            </Box>

            <Box component="section" backgroundColor="#4771DF" pt={10} pb={10}>
                <Box className='section-content' >
                    <Box textAlign="center" fontSize={{xs:20, md:30}} fontWeight="bold" color="white"><Box component="span" fontSize={{xs:30, md:45}} mr={1}>助成金制度</Box>について</Box>
                    <Box margin="0 auto" width={80} height={2} backgroundColor="white" mt={2} mb={12}></Box>

                    {faqSubsidyList.map((item,index)=>{
                        return(
                            <Box key={index} borderRadius="20px" backgroundColor="white" fontSize={{xs:16, md:18}} fontWeight="bold" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px" mb={2} sx={{cursor:"pointer"}} onClick={()=>{
                                item.isOpen=!item.isOpen
                                setFaqSubsidyList([...faqSubsidyList])  
                            }}>
                                <Stack flexDirection="row" backgroundColor={item.isOpen===false ? "inherit":"#D1EAFE"} borderRadius={item.isOpen===false ? "20px":"20px 20px 0 0"} p={3} >
                                    <Box flex={1} >{item.q}</Box>
                                    <Box color="#4771DF">{item.isOpen===false ? "＋":"×"}</Box>
                                </Stack>
                                {item.isOpen===true &&
                                    <Box p={3} fontWeight="normal">{item.a}</Box>
                                }
                            </Box>
                        )
                    })}
                    

                </Box>
            </Box>

            <Box component="section" backgroundColor="white" pt={10} pb={10}>
                <Box className='section-content' >
                    <Box textAlign="center" fontSize={{xs:20, md:30}} fontWeight="bold"><Box component="span" fontSize={{xs:30, md:45}} mr={1}>助成金コーディネート</Box>について</Box>
                    <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={2} mb={12}></Box>

                    {faqJcoordinateList.map((item,index)=>{
                        return(
                            <Box key={index} borderRadius="20px" backgroundColor="#D1EAFE" fontSize={{xs:16, md:18}} fontWeight="bold" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px" mb={2} sx={{cursor:"pointer"}} onClick={()=>{
                                item.isOpen=!item.isOpen
                                setFaqJcoordinateList([...faqJcoordinateList])  
                            }}>
                                <Stack flexDirection="row" backgroundColor={item.isOpen===false ? "inherit":"#D1EAFE"} borderRadius={item.isOpen===false ? "20px":"20px 20px 0 0"} p={3} >
                                    <Box flex={1} >{item.q}</Box>
                                    <Box color="#4771DF">{item.isOpen===false ? "＋":"×"}</Box>
                                </Stack>
                                {item.isOpen===true &&
                                    <Box p={3} fontWeight="normal" backgroundColor="white" borderRadius="0 0 20px 20px">{item.a}</Box>
                                }
                            </Box>
                        )
                    })}
                    

                </Box>
            </Box>

            <Box component="section" backgroundColor="#4771DF" textAlign="center" pt={10} pb={10}>
                <Box className='section-content' textAlign="center" color="white">
                    <Box fontSize={{xs:20, md:30}}><Box component="span" fontSize={{xs:30, md:45}} mr={1}>助成金、</Box>どうせやるなら<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>「フル活用」</Box>！</Box>
                    
                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" mt={10} gap={5}>
                        
                        <Stack alignItems="center" gap={4}>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA1.png"} alt="CTA1" width="100%" maxWidth={300}></Box>

                            <Box width="100%">
                                <Box fontSize={16} color="white">たった<Box component="span" fontSize={30}>５</Box>分で<Box component="span" fontSize={30}>カンタン</Box>!</Box>
                                <ConversionButtonA display="block" fontSize={{xs:20, md:30}} >助成金診断<Box component="span" ml={1} fontSize={{xs:16, md:20}}>する</Box></ConversionButtonA>
                            </Box>
                        </Stack>
                        
                        
                        <Stack alignItems="center" gap={4}>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA2.png"} alt="CTA2" width="100%" maxWidth={300}></Box>
                            <Stack flex={1} justifyContent="end" width="100%">
                                
                                <ConversionButtonB display="block" fontSize={{xs:20, md:30}}><Box component="span"></Box>プロに相談<Box component="span" ml={1} fontSize={{xs:16, md:20}}>する</Box></ConversionButtonB>
                            </Stack>
                        </Stack>
                        
                    </Stack>
                </Box>
            </Box>

            <Footer/>
        </>
    )
}

export default Faq;