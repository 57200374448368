import React from 'react';
import {Box,Stack} from '@mui/material'
import Header from "./Header"
import Footer from './Footer';


function Customer1() {

    return (
        <>
            <Header/>
            <Box className='section-content' margin="0 auto" fontSize={{xs:16, md:16}} pt={15} pb={15} pl={3} pr={3}>

                <Box component="h1" fontSize={30} textAlign="center" fontWeight="bold" color="#007bff" mb={10}>従業員の働きやすい環境づくりから、しっかりサポートしてくれる</Box>

                <Box p={{xs:"20px",md:"30px 100px"}} backgroundColor="#efefef" >

                    <Stack flexDirection={{xs:"column-reverse", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                        <Box>
                            <Box fontSize={{xs:16, md:18}} fontWeight="bold" >株式会社にこいち</Box>
                            <Box mt={2}>業種：広告・あっせん業</Box>
                            <Box mt={1}>サービス：Web制作/企画/広告代理/運営広告撮影/記事作成</Box>
                            <Box mt={1}>従業員数：7名</Box>
                        </Box>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer1_Logo.png"} alt="Customer1_Logo" width="100%" maxWidth={300}></Box>
                    </Stack>

                    <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2} mt={2}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer1_Rep.jpg"} alt="Customer1_Rep" width="100%" maxWidth={400}></Box>
                        <Box>
                            <Box>札幌を中心に、ホームページ制作、Web企画、運営を行う、株式会社にこいち様。</Box>
                            <Box mt={1}>ホームページを完成させ納品したらおしまいというスタイルではなく、完成後のサイト運営にも継続的に関わり、お客様の売上やビジョン達成に貢献し、二人三脚（お客様とふたりでひとつ：にこいち）で企業の真の目的を叶えていくサービスを展開しています。</Box>
                            <Box mt={1}>今回は、その代表取締役の佐藤 亮佑様にお話を伺いました。</Box>
                        </Box>
                        
                    </Stack>
                </Box>

                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">従業員のためにもなって、会社の利益にもなる「助成金」／活用しない理由がない！</Box>
                <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                    
                    <Box>
                        <Box fontWeight="bold">――『助成金コーディネート』に出会う以前から、厚生労働省管轄の「助成金」は活用していたのでしょうか？</Box>
                        <Box mt={1}>はい。異業種交流会で知り合った社労士さんに「キャリアアップ助成金」の話を聞いたことがあったんです。自分でも調べた上で、これは活用した方が良いと思い、当時は異業種交流会で出会った社労士さんに申請の依頼をしました。</Box>

                        <Box mt={5} fontWeight="bold">―― 以前より、厚生労働省管轄の「助成金」には、どのような点にメリットがあると感じていたんですか？</Box>
                        <Box mt={1}>「助成金」は、従業員のために働きやすい環境をつくった会社がもらえるものなので、従業員のためにもなるし、会社の利益にもなるものだと理解できたんです。なので、これは活用しない理由がない！と思いました。</Box>
                    </Box>
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer1_03.jpg"} alt="Customer1_03" width="100%" maxWidth={500}></Box>
                    
                </Stack>

                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">『助成金コーディネート』は、従業員の働きやすい環境づくりからしっかりサポートしてくれるサービス</Box>
                <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>

                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer1_01.jpg"} alt="Customer1_01" width="100%" maxWidth={500}></Box>
                    <Box>
                        <Box fontWeight="bold">―― 御社は元々「助成金」申請をしていたのになぜ、TRIPORTの『助成金コーディネート』にお申し込みしようと思われたのでしょうか？お問い合わせ以前はどのような印象でしたか？</Box>
                        <Box mt={1}>ちょうど一通りの申請が終わって落ち着いた頃に、「無料の助成金診断サービス」のWEB広告を見つけたんです。「助成金」というキーワードが目について気にはなっていたものの、自社でも「助成金」はこれまでに申請していたし、うちの会社はこれ以上もらえる「助成金」はないだろう、と思っていました。自分の会社にメリットは無く、ただ企業情報だけ取られて終わりだろう、と思っていましたね（笑）。それに、少し怪しいサービスなんじゃないかと思って、実は警戒していました。何度か広告を目にしたものの、すぐには問い合わせはしなかったんですが、やはり話だけ聞いてみようと思い、無料診断を受けてみました。</Box>
                    </Box>
                    
                </Stack>

                <Box mt={5} fontWeight="bold">―― 最初は懐疑的だったんですね！実際『助成金コーディネート』についてのお話を聞いてみて、印象は変わりましたか？</Box>
                <Box mt={1}>話を聞いてみたら、凄く印象が良かったんです。このサービスは、ただ「助成金」をもらう事だけが目的ではなく、まず会社が従業員の働きやすい環境をしっかりつくり、その結果「助成金」を受給できる、というしくみのサービスだったんです。本当の意味で、会社の成長を応援してくれようとしているサービスだと分かりました。</Box>
                <Box>サービス説明をしてくれたTRIPORTさんの助成金アドバイザーの話から、このサービスにかける「想い」や「信念」を感じたのも大きかったです。目先の利益でなく、会社を成長させるために本当に必要な課題解決をするためのサポートをしっかりする、というコンセプトは、弊社がサービス提供する時にも大事にしていることなので、共通の理念を感じ、凄く共感できました。</Box>

                <Box mt={5} fontWeight="bold">――『助成金コーディネート』に申し込んだ一番の決め手は何でしたか？</Box>
                <Box mt={1}>やはり、労務周りの整備がきちんとできる点です。</Box>
                <Box >弊社は、創業時にインターネットで調べながら自分で作成した就業規則などを使っていました。従業員のためにも、きちんとつくりかえなければと感じてはいましたが、なかなかきっかけもなく、手をつけられずにいました。『助成金コーディネート』に申し込んだら、労務周りの整備がしっかりできると思い、背中を押してもらったと感じています。</Box>

                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">レスも早く、回答も明快で、ストレスがなかった</Box>
                <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>

                    <Box>
                        <Box fontWeight="bold">―― 実際に『助成金コーディネート』を受けてみて、いかがでしたか？</Box>
                        <Box mt={1}>とても満足しています。</Box>
                        <Box >チャットでのやり取りも凄くスムーズで、コミュニケーションが取りやすかったです。レスも早かったですし、文字だけで理解しにくい内容については、電話も活用しながら説明していただけたので、ストレスがありませんでした。</Box>

                        <Box mt={5} fontWeight="bold">―― TRIPORTの助成金アドバイザーやコンサルタントとも仲良しですよね！</Box>
                        <Box mt={1}>はい！信頼関係をつくるためには、直接会うことが大事だと個人的には凄く思っているんです！TRIPORTさんのサービスは全てがオンラインのサービスだったのにもかかわらず、コミュニケーションもスムーズで、不安もなく進めていけました。オンラインでもこれだけできるんだ！という発見もあり、勉強になりました。</Box>
                        <Box >何気なく利用したサービスでしたが、最終的に凄く満足しています。</Box>
                    </Box>
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer1_04.jpg"} alt="Customer1_01" width="100%" maxWidth={500}></Box>

                </Stack>

                <Box mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">いつかはやりたいと思っていた労務周りの整備ができ、従業員の定着にも期待</Box>
                <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer1_05.jpg"} alt="Customer1_05" width="100%" maxWidth={500}></Box>
                    <Box>
                        <Box fontWeight="bold">―― この１年で従業員数も増えていますよね！『助成金コーディネート』で効果につながったと感じていることはありますか？</Box>
                        <Box mt={1}>助成金活用の準備で必須となる、労務周りの整備ができたことで、従業員が定着しやすくなったのでは、と感じています。 月に一度、社長と従業員の間で面談を実施しているのですが、その中で、例えば有期契約で入社した従業員に対しては正社員になるための目標設定を共に考え、この先のビジョンを明確に見せられるようになりました。また、従業員がしっかりと評価されるような人事評価制度づくりに会社が取り組んでいる、という姿勢を見せることで、以前より、従業員がモチベーションを保ちやすくなったり、会社に定着しやすくなるのでは、と感じています。</Box>

                        <Box mt={5} fontWeight="bold">―― これからさらに取り組んでいきたいことはありますか？</Box>
                        <Box mt={1}>せっかく制度をつくったので、ただつくって満足するのではなく、ここからしっかり従業員に落とし込んでいきたいと思っています。実は今日の朝礼で、ちょうどTRIPORTさんのことを従業員に話したところなんです。「みんなの働く環境を良くするためにお手伝いしてくれている会社だ」という話をしました。</Box>
                        <Box mt={1}>つくった制度等を活用するためにも、従業員に目的や意図を伝え続けることが大切だと感じています。そのことで、従業員のモチベーションアップ、帰属意識の向上にも繋がるのではないか、とも感じています。</Box>
                    </Box>
                    

                </Stack>

                <Box component="h2" mt={10} mb={5} color="#007bff" fontSize={{xs:24, md:24}} fontWeight="bold" textAlign="center">会社を成長させたい！人材採用に力を入れていきたい！と思っている会社にオススメ</Box>
                <Stack flexDirection={{xs:"column", md:"row"}} justifyContent="space-between" alignItems="center" gap={2}>
                    
                    <Box>
                        <Box fontWeight="bold">―― 最後に、『助成金コーディネート』は、どのような企業様におすすめできると思いますか？</Box>
                        <Box mt={1}>「会社を成長させたい！」「人材採用に力を入れていきたい！」と思っている会社であれば、まずTRIPORTさんの『助成金コーディネート』を受けたらいいと思います。 とにかく労務周りを改善することで働く人も会社も良くなるわけで、「助成金」は結果的にもらえるものです。 大切なのは、目先のお金を得る事だけでなく、従業員が働きやすい環境をつくることで、結果的に会社が利益を得て、成長していく事だと思います。</Box>
                        <Box mt={1}>私の周りでも、勢いのある会社さん・人を大切にしたいと思っている会社さんには、『助成金コーディネート』を紹介しています。</Box>
                    </Box>
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Customer1_02.jpg"} alt="Customer1_01" width="100%" maxWidth={500}></Box>

                </Stack>

                <Box mt={10} fontWeight="bold">―― 「助成金」活用の中で、労務周りの整備のお役に立てて非常に嬉しく思います。 佐藤様、本日はありがとうございました。</Box>
            </Box>
            <Footer/>
        </>
    );
}

export default Customer1;