import React from 'react';
import {Link} from '@mui/material'

import { useSearchParams } from "react-router-dom";

function ConversionButtonB(props) {

    const [searchParams] = useSearchParams();
    
    const baseUrl = "https://triport.co.jp/form/jstock/entry"

    let partnerId = searchParams.get("id");
    let campainId = searchParams.get("ca");

    if(partnerId == null){
        partnerId = "8f70b68dfe86450fad06adf41c8f4fb2"
    }

    let targetUrl = `${baseUrl}?p=${partnerId}`;

    if(campainId!=null){
        targetUrl += `&ca=${campainId}`
    }
    
    return (
        <Link 
            display={props.display} 
            fontSize={props.fontSize} 
            textAlign={props.textAlign}
            mt={props.mt}
            color="#FFFFFF" 
            borderRadius={props.borderRadius} 
            backgroundColor="#ED346C" 
            border="solid 3px #ED346C" 
            p={props.p} 
            fontWeight="bold" 
            width={props.width}
            maxWidth={props.maxWidth}
            href={targetUrl} 
            target={props.target}
            sx={{
                textDecoration:"none",
                "&:hover":{
                    color:"#ED346C",
                    backgroundColor:"white",
                    
                },
            }}>{props.children}</Link>
    );
}

ConversionButtonB.defaultProps = {
    target:"_blank",
    p:"10px 30px",
    borderRadius:50
}

export default ConversionButtonB;