import React from 'react';
import {Box} from '@mui/material'
import Header from "./Header"
import Footer from './Footer';

function LeagalCompliance(){

    

    return (
        <>
            <Header/>
            <Box className='section-content' margin="0 auto" pt={15} pb={15} pl={3} pr={3}>
                
                    <Box component="h1" fontWeight="bold" fontSize={{xs:30, md:45}} textAlign="center">法令遵守について</Box>
                    <Box mt={5}>
                        
                        <Box>TRIPORT株式会社およびTRIPORT社会保険労務士法人（以下、当グループという。）は、TRIPORT株式会社が行う助成金コーディネート（以下、当サービスという。）の法令遵守について、以下の通り表明します。</Box>
                        
                        <Box mt={5}>（前提）本見解について</Box>
                        <Box >当グループにおいては、当サービスの運営にあたり法令を遵守することを前提としており、本見解については弁護士を交え、各種法令等に沿った十分な検討を行っております。なお、お客様に安心して当サービスをご利用頂けるよう、今後も随時サービス内容の精査・検討・改良を続けてまいります。</Box>
                        
                        <Box component="h2" fontSize={{xs:16, md:20}} fontWeight="bold" mt={5}>1. 当サービスと社労士法27条との関係性について</Box>
                        <Box>当サービスは社労士法27条に違反いたしません。</Box>
                        
                        <Box component="h2" fontSize={{xs:16, md:20}} fontWeight="bold" mt={5}>1-2. 当サービスが社労士法27条に違反しない理由について</Box>
                        <Box>社労士法2条1項1号から2号までに掲げる業務はTRIPORT社会保険労務士法人が行っており、当サービスにおいてTRIPORT株式会社が行っている業務は同号に規定するものではありません。そして、TRIPORT社会保険労務士法人とTRIPORT株式会社は、それぞれが顧客と直接契約を締結し、費用も顧客から直接受領しております。また、当グループ間において、紹介料等の金銭の授受は行っておりません。<br/>以上のとおり、 当サービスにおいて、TRIPORT株式会社が社労士法2条1項1号から2号までに掲げる業務を受託し、又は遂行することはないため、当サービスは社労士法27条に違反いたしません。</Box>
                        
                        <Box component="h2" fontSize={{xs:16, md:20}} fontWeight="bold" mt={5}>2. 当サービスと弁護士法72条との関係性について</Box>
                        <Box>当サービスは弁護士法72条に違反いたしません。</Box>
                        
                        <Box component="h2" fontSize={{xs:16, md:20}} fontWeight="bold" mt={5}>当サービスが弁護士法72条に違反しない理由について</Box>
                        <Box>社労士法第2条第1項第1号から2号までに掲げる業務はTRIPORT社会保険労務士法人が行っております。そして、当サービスにおいてTRIPORT株式会社が行っている業務は、助成金に関する一般的な説明等であり当該業務は弁護士法72条の法律事務に該当するものではありません。したがいまして、当サービスは同条に違反いたしません。</Box>
                        
                        <Box component="h2" fontSize={{xs:16, md:20}} fontWeight="bold" mt={5}>3. TRIPORT株式会社と助成金申請担当社労士との関係性について</Box>
                        <Box>助成金申請担当社労士は社労士法23条の2に違反いたしません。</Box>
                        
                        <Box component="h2" fontSize={{xs:16, md:20}} fontWeight="bold" mt={5}>3-2. 助成金申請担当社労士が社労士法23条の2に違反しない理由について</Box>
                        <Box>1-2のとおり、当サービスは社労士法27条に違反することはございませんので、当サービスを前提に助成金申請担当社労士が助成金申請を行うことは社労士法23条の2に違反いたしません。<br/>なお、助成金申請担当社労士は、助成金申請に関して顧客と直接契約を締結し、費用も顧客から直接受領しております。そして、TRIPORT株式会社と助成金申請担当社労士間において、助成金申請に関する紹介料等の金銭の授受は行っておりません。</Box>
                        
                        <Box mt={5}>以上</Box>
                        
                    </Box>
                
            </Box>
            <Footer/>
        </>
    )
}

export default LeagalCompliance;