import React from 'react';
import {Box,Stack} from '@mui/material'
import ConversionButtonA from "../../Components/ConversionButtonA";
import ConversionButtonB from "../../Components/ConversionButtonB";
import Header from './Header';
import Footer from './Footer';


function Company(){

   

    return (
        <>
            <Header/>

            <Box component="section" backgroundColor="#D1EAFE" pt={30} pb={20}>
                <Box className='section-content' >
                    <Box component="h1" textAlign="center" fontSize={{xs:20, md:30}} fontWeight="bold">TRIPORTグループについて</Box>
                </Box>
            </Box>

            <Box component="section" backgroundColor="#EBEBEB" pt={10} pb={10}>
                <Box className='section-content' >

                    <Box component="h1" fontWeight="bold" textAlign="center">
                        <Box fontSize={{xs:20, md:30}}>TRIPORTグループの</Box>
                        <Box fontSize={{xs:30, md:45}}>特徴</Box>
                    </Box>

                    <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={2} mb={5}></Box>

                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" gap={3} mt={10} color="#4771DF" textAlign="center">

                        <Stack alignItems="center" flexBasis="30%">
                            <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="white">
                                <Box  fontSize={50} fontWeight="bold" >01</Box>
                            </Stack>
                            <Stack alignItems="center" backgroundColor="white" borderRadius={3} p={2} pt={4} flex={1} width="100%">
                                <Box component="h3" fontWeight="bold" mb={2} zIndex={1} flex={1}>
                                    <Box fontSize={22}>「働き方改革」<Box component="span" fontSize={14} pl={1} pr={1}>の</Box>自社実践企業</Box>
                                </Box>
                                <Box backgroundColor="white" borderRadius={2} width="100%">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TRIPORT_01.png"} alt="TRIPORT_01" width="100%" ></Box>
                                </Box>
                                
                            </Stack>
                        </Stack>

                        <Stack alignItems="center" flexBasis="30%">
                            <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="white">
                                <Box  fontSize={50} fontWeight="bold">02</Box>
                            </Stack>
                            <Stack alignItems="center" backgroundColor="white" borderRadius={3} p={2} pt={4} flex={1} width="100%">
                                <Box component="h3" fontWeight="bold" mb={2} zIndex={1} flex={1}>
                                    <Box fontSize={22}>社労士<Box component="span" fontSize={14} pl={1} pr={1}>業界の中で、</Box>ITに強い</Box>
                                </Box>
                                <Box backgroundColor="white" borderRadius={2} width="100%">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TRIPORT_02.png"} alt="TRIPORT_02" width="100%"></Box>
                                </Box>
                                
                            </Stack>
                        </Stack>

                        <Stack alignItems="center" flexBasis="30%">
                            <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="white">
                                <Box  fontSize={50} fontWeight="bold">03</Box>
                            </Stack>
                            <Stack alignItems="center" backgroundColor="white" borderRadius={3} p={2} pt={4} flex={1} width="100%">
                                <Box component="h3" fontWeight="bold" mb={2} zIndex={1} flex={1}>
                                    <Box fontSize={14}>あらゆる<Box component="span" fontSize={22} pl={1} pr={1}>業界のトップランナー</Box>と共に、</Box>
                                    <Box fontSize={22}>社会課題解決<Box component="span" fontSize={14} pl={1} pr={1}>に取り組む</Box></Box>
                                    
                                </Box>
                                <Box backgroundColor="white" borderRadius={2} width="100%">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TRIPORT_03.png"} alt="TRIPORT_03" width="100%" ></Box>
                                </Box>
                                
                            </Stack>
                        </Stack>
                        
                    </Stack>
                    
                </Box>
            </Box>

            <Box component="section" backgroundColor="#D1EAFE" pb={10}>
                <Box className='section-content' >

                    <Box margin="0 auto" mt={-1} width="30%" height={{xs:"50px",md:"100px"}} sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)",transform:"translateY(-1px)"}} backgroundColor="#EBEBEB" ></Box>
                    
                    <Box mt={5} p={{xs:1,md:5}} backgroundColor="white" borderRadius={2}>
                        <Stack flexDirection={{xs:"column",md:"row"}} alignItems="center" width="100%" gap={{xs:2,md:5}}>

                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TRIPORT_01.png"} alt="TRIPORT_01" width="100%" maxWidth={500}></Box>

                            <Stack flex={1} gap={2} fontWeight="bold">
                                <Stack component="h2"  alignItems="center" flexDirection="row" gap={1} >
                                    <Box fontSize={{xs:40,md:80}} lineHeight={1} color="#4771DF">01</Box>
                                    <Box component="span" fontSize={{xs:20,md:30}}>「働き方改革」<Box component="span" fontSize={{xs:16,md:20}} ml={1} mr={1}>の</Box>自社実践企業</Box>
                                </Stack>
                                <Box mt={2}>TRIPORTは、企業と働き手とが幸せになれる働き方改革に取り組んでいます。実際に効果が上がった理論や検証結果、また、第三者評価を得られているナレッジ・ノウハウをプロダクトに反映してお客様に求められる形でご提供しています。</Box>
                            </Stack>
                        </Stack>

                        
                        <Box backgroundColor="#EBEBEB" mt={10} p={{xs:2,md:5}} textAlign="center" position="relative">
                            <Stack component="h2" flexDirection="row" justifyContent="center" color="white" textAlign="center" sx={{transform:"translateY(-50%)"}} position="absolute" left={0} right={0} top={0}>
                                <Box component="h2"  p={1} width={{xs:"95%",md:"70%"}} backgroundColor="#4771DF" color="white" fontSize={{xs:16, md:16}} fontWeight="bold" sx={{clipPath:"polygon(0 0, 100% 0, calc(100% - 15px) 50%, 100% 100%, 0 100%, 15px 50%)"}}>
                                    <Box component="span" fontSize={{xs:25, md:30}} pl={1} pr={1}>働き方</Box>
                                    に関する
                                    <Box component="span" fontSize={{xs:25, md:30}} pl={1} pr={1}>受賞・表彰・認定</Box>等
                                </Box>
                            </Stack>

                            <Box backgroundColor="white" p={5} mt={{xs:6,md:5}}>
                                <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly"  gap={3} >

                                    <Box flexBasis="30%" component="img" src={process.env.PUBLIC_URL+"/static/media/LP/award_01.png"} alt="award_01" width="100%" maxWidth={200} ></Box>
                                    <Box flexBasis="30%" component="img" src={process.env.PUBLIC_URL+"/static/media/LP/award_02.png"} alt="award_02" width="100%" maxWidth={200} ></Box>
                                    <Box flexBasis="30%" component="img" src={process.env.PUBLIC_URL+"/static/media/LP/award_03.png"} alt="award_03" width="100%" maxWidth={200} ></Box>
                                    
                                </Stack>

                                <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" backgroundColor="white" gap={3} mt={3}>

                                    <Box flexBasis="30%" component="img" src={process.env.PUBLIC_URL+"/static/media/LP/award_04.png"} alt="award_04" width="100%" maxWidth={200} ></Box>
                                    <Box flexBasis="30%" component="img" src={process.env.PUBLIC_URL+"/static/media/LP/award_05.png"} alt="award_05" width="100%" maxWidth={200} ></Box>
                                    <Box flexBasis="30%" component="img" src={process.env.PUBLIC_URL+"/static/media/LP/award_06.png"} alt="award_06" width="100%" maxWidth={200} ></Box>

                                </Stack>
                            </Box>
                        </Box> 
                    </Box>

                    <Box p={{xs:1,md:5}} mt={10} backgroundColor="white" borderRadius={2}>
                        <Stack flexDirection={{xs:"column",md:"row"}} alignItems="center" width="100%" gap={{xs:2,md:5}}>

                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TRIPORT_02.png"} alt="TRIPORT_02" width="100%" maxWidth={500}></Box>

                            <Stack flex={1} gap={2} fontWeight="bold">
                                <Stack component="h2"  alignItems="center" flexDirection="row"  gap={1}>
                                    <Box fontSize={{xs:40,md:80}} lineHeight={1} color="#4771DF">02</Box>
                                    <Box component="span" fontSize={{xs:20,md:30}}>社労士<Box component="span" fontSize={{xs:16,md:20}} ml={1} mr={1}>業界の中で、</Box>IT<Box component="span" fontSize={{xs:16,md:20}} ml={1}>に強い</Box></Box>
                                </Stack>
                                <Box mt={2}>ITによる業務効率化がなかなか進まない士業界。TRIPORTは、ITのチカラを活用し、全国企業の経営課題に対し、よりリーズナブルで、より効率的に解決できる取り組みを行っています。</Box>
                            </Stack>
                        </Stack>

                        
                        <Box backgroundColor="#EBEBEB" mt={10} p={{xs:2,md:5}} textAlign="center" position="relative">
                            <Stack component="h2" flexDirection="row" justifyContent="center" color="white" textAlign="center" sx={{transform:"translateY(-50%)"}} position="absolute" left={0} right={0} top={0}>
                                <Box component="h2" p={{xs:"8px 16px",md:1}} width={{xs:"95%",md:"70%"}} backgroundColor="#4771DF" color="white" fontSize={{xs:16, md:16}} fontWeight="bold" sx={{clipPath:"polygon(0 0, 100% 0, calc(100% - 15px) 50%, 100% 100%, 0 100%, 15px 50%)"}}>高付加価値なサービスを低料金で安定的に提供していることが第三者機関によって評価</Box>
                            </Stack>

                            
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/logo_mhlw.png"} alt="logo_mhlw" width="100%" maxWidth={200}></Box>
                            <Box fontSize={{xs:30,md:45}} fontWeight="bold">第10回 日本HRチャレンジ大賞</Box>

                            <Stack flexDirection={{xs:"column",md:"row"}} alignItems="center" width="100%" backgroundColor="white" mt={2} gap={{xs:2,md:5}} p={2}>
                                <Box textAlign="left" fontWeight="bold">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/reason-for-award.png"} alt="reason-for-award" width="100%" maxWidth={100}></Box>
                                    <Box fontSize={16}>法改正や助成⾦情報等の最新情報提供や各種労務相談、規程・帳簿等確認、改善指導及び作成代行、助成⾦活用サポートに至るまで、人事労務まわりのお悩みを解決する完全オンライン型サービス</Box>
                                    <Box fontSize={20} mt={2}><Box component="span" color="#4771DF" fontSize={{xs:20,md:30}}>ITのチカラ</Box>によって、全国の中小企業に対して<Box component="span" color="#4771DF">高付加価値なサービスを低料金で安定的に提供</Box>している点が高く評価されました。</Box>
                                </Box>
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/HR-challenge.png"} alt="HR-challenge" width="100%" maxWidth={500}></Box>
                            </Stack>

                            <Box textAlign="right" fontSize={11} mt={1}>※ 日本HRチャレンジ大賞は、助成金コーディネートの元となるサービスである「クラウド社労士コモン」が受賞しました。</Box>
                        </Box> 
                    </Box>

                    <Box p={{xs:1,md:5}} mt={10} backgroundColor="white" borderRadius={2}>
                        <Stack flexDirection={{xs:"column",md:"row"}} alignItems="center" width="100%" gap={{xs:2,md:5}}>

                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TRIPORT_03.png"} alt="TRIPORT_03" width="100%" maxWidth={500}></Box>

                            <Stack flex={1} gap={2} fontWeight="bold">
                                <Stack component="h2"  alignItems="center" flexDirection="row"  gap={1}>
                                    <Box fontSize={{xs:40,md:80}} lineHeight={1} color="#4771DF">03</Box>
                                    <Box component="span" fontSize={{xs:16,md:20}} >あらゆる<Box component="span" fontSize={{xs:20,md:30}} ml={1} mr={1}>業界のトップランナー</Box>と共に、<Box component="span" fontSize={{xs:20,md:30}} ml={1} mr={1}>社会課題解決</Box>に取り組む</Box>
                                </Stack>
                                <Box mt={2}>人事・労務の専門家である社労士だが、全社労士のうちでも対応できる社労士はわずか1%*だといわれている「助成金活用」や、高度な専門性が求められる「労務監査」など、士業界の中でも希少なノウハウ・ナレッジを豊富に保有。高度な専門性のみならず、ITのチカラを活用し、HR業界でも独自のポジショニングでの事業展開を行っています。</Box>
                                <Box textAlign="right">*社労士の中でも「あらゆる助成金をフル活用できる社労士」の割合</Box>
                            </Stack>
                        </Stack>

                        
                        <Box backgroundColor="#EBEBEB" mt={10} p={{xs:2,md:5}} textAlign="center" position="relative">
                            <Stack component="h2" flexDirection="row" justifyContent="center" color="white" textAlign="center" sx={{transform:"translateY(-50%)"}} position="absolute" left={0} right={0} top={0}>
                                <Box component="h2"  p={1} width={{xs:"95%",md:"70%"}} backgroundColor="#4771DF" color="white" fontSize={{xs:16, md:16}} fontWeight="bold" sx={{clipPath:"polygon(0 0, 100% 0, calc(100% - 15px) 50%, 100% 100%, 0 100%, 15px 50%)"}}>ポジショニングマップ</Box>
                            </Stack>

                            <Box mt={5} component="img" src={process.env.PUBLIC_URL+"/static/media/LP/positioning-map.png"} alt="positioning-map" width="100%"></Box>
                        </Box> 

                        <Box  mt={10} p={{xs:2,md:5}} textAlign="center" position="relative" borderTop="2px dashed #4771DF">
                            <Stack component="h2" flexDirection="row" justifyContent="center" color="white" textAlign="center" sx={{transform:"translateY(-50%)"}} position="absolute" left={0} right={0} top={0}>
                                <Box component="h2"  p={1} width={{xs:"95%",md:"70%"}} backgroundColor="#4771DF" color="white" fontSize={{xs:16, md:16}} fontWeight="bold" sx={{clipPath:"polygon(0 0, 100% 0, calc(100% - 15px) 50%, 100% 100%, 0 100%, 15px 50%)"}}>業務提携</Box>
                                {/* <Box display="inline-block" width="30px" sx={{clipPath:"polygon(0 0, 100% 0, 100% 100%, 0 100%, 100% 50%)", transform:"translateX(1px)"}} backgroundColor="#4771DF"></Box>
                                <Box p={1} pl={{xs:1,md:5}} pr={{xs:1,md:5}} minWidth="30%" backgroundColor="#4771DF" fontSize={{xs:16, md:16}} fontWeight="bold">業務提携</Box>
                                <Box display="inline-block" width="30px" sx={{clipPath:"polygon(100% 0, 0 0, 0 100%, 100% 100%, 0 50%)", transform:"translateX(-1px)"}} backgroundColor="#4771DF"></Box> */}
                            </Stack>
                            <Stack flexDirection="row" flexWrap="wrap" mt={5} gap={1} alignItems="center" width="100%" justifyContent="space-evenly">
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/logo_cw.png"} alt="logo_cw" width="auto" maxWidth={{xs:"30%",md:"15%"}}></Box>
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/logo_kmj.png"} alt="logo_kmj" width="auto" maxWidth={{xs:"30%",md:"15%"}}></Box>
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/logo_sms.png"} alt="logo_sms" width="auto" maxWidth={{xs:"30%",md:"15%"}}></Box>
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/logo_gmo.png"} alt="logo_gmo" width="auto" maxWidth={{xs:"30%",md:"15%"}}></Box>
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/logo_bdc.png"} alt="logo_bdc" width="auto" maxWidth={{xs:"30%",md:"15%"}}></Box>
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/logo_donuts.png"} alt="logo_donuts" width="auto" maxWidth={{xs:"30%",md:"15%"}}></Box>
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/logo_os.png"} alt="logo_os" width="auto" maxWidth={{xs:"30%",md:"15%"}}></Box>
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/logo_nz.png"} alt="logo_nz" width="auto" maxWidth={{xs:"30%",md:"15%"}}></Box>
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/logo_ds.png"} alt="logo_ds" width="auto" maxWidth={{xs:"30%",md:"15%"}}></Box>
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/logo_umee.png"} alt="logo_umee" width="auto" maxWidth={{xs:"30%",md:"15%"}}></Box>
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/logo_telework.png"} alt="logo_telework" width="auto" maxWidth={{xs:"30%",md:"15%"}}></Box>
                            </Stack>
                            
                            
                        </Box> 
                    </Box>
 
                </Box>
            </Box>

            

            {/* <Box component="section" backgroundColor="#EBEBEB" pb={10} pt={10} textAlign="center">
                <Box className='section-content' >
                    <Stack alignItems="center">
                        <Link href={"/"} target="_blank" backgroundColor="#4771DF" sx={{color:"white"}} fontWeight="bold" textAlign="center" mt={2} p={5} maxWidth={"95%"} width={400} borderRadius={2}>私たちの想いはこちら</Link>
                    </Stack>
                </Box>
            </Box> */}

            

            <Box component="section" backgroundColor="#4771DF" textAlign="center" pt={10} pb={10}>
                <Box className='section-content' textAlign="center" color="white">

                    <Box fontSize={{xs:20, md:30}} fontWeight="bold">
                        <Box>会社の<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>労務、助成金</Box>のことなら</Box>
                        <Box><Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>TRIPORT</Box>に<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>お任せ</Box>ください！</Box>
                    </Box>
                    
                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" mt={10} gap={5}>
                        
                        <Stack alignItems="center" gap={4}>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA1.png"} alt="CTA1" width="100%" maxWidth={300}></Box>

                            <Box width="100%">
                                <Box fontSize={16} color="white">たった<Box component="span" fontSize={30}>５</Box>分で<Box component="span" fontSize={30}>カンタン</Box>!</Box>
                                <ConversionButtonA display="block" fontSize={{xs:20, md:30}} >助成金診断<Box component="span" ml={1} fontSize={{xs:16, md:20}}>する</Box></ConversionButtonA>
                            </Box>
                        </Stack>
                        
                        
                        <Stack alignItems="center" gap={4}>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA2.png"} alt="CTA2" width="100%" maxWidth={300}></Box>
                            <Stack flex={1} justifyContent="end" width="100%">
                                
                                <ConversionButtonB display="block" fontSize={{xs:20, md:30}}><Box component="span"></Box>プロに相談<Box component="span" ml={1} fontSize={{xs:16, md:20}}>する</Box></ConversionButtonB>
                                
                            </Stack>
                        </Stack>
                        
                    </Stack>
                </Box>
            </Box>

            <Footer/>
        </>
    )
}

export default Company;