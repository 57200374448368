import React from 'react';
import {Box,Stack} from '@mui/material'
import ConversionButtonA from "../../Components/ConversionButtonA";
import ConversionButtonB from "../../Components/ConversionButtonB";
import Header from './Header';
import Footer from './Footer';
import AccentBox from '../../Components/AccentBox';

function Service(){

    const before_checkMark_blue={
        '&::before':{
            content:'""', 
            display:"inline-block", 
            verticalAlign:"middle", 
            mr:"12px", 
            width:"20px", 
            height:"20px", 
            minWidth:"20px",
            background:"url("+process.env.PUBLIC_URL+"/static/media/LP/checkbox1.png) no-repeat",backgroundSize:"contain"
        }
    }

    const before_checkMark_pink={
        '&::before':{
            content:'""', 
            display:"inline-block", 
            verticalAlign:"middle", 
            mr:"12px", 
            width:"20px", 
            height:"20px", 
            minWidth:"20px",
            background:"url("+process.env.PUBLIC_URL+"/static/media/LP/checkbox3.png) no-repeat",backgroundSize:"contain"
        }
    }

    return (
        <>
            <Header/>

            <Box component="section" backgroundColor="#D1EAFE" pt={30} pb={20}>
                <Box className='section-content' >
                    <Box component="h1" textAlign="center" fontSize={{xs:20, md:30}} fontWeight="bold">助成金コーディネートでできること</Box>
                </Box>
            </Box>

            <Box component="section" backgroundColor="white" pt={10} pb={10}>
                <Box className='section-content' textAlign="center">
                    <Box component="h1" fontSize={{xs:20, md:30}} fontWeight="bold" mt={2}><Box component="span" fontSize={{xs:30, md:45}} pl={1} pr={1}>助成金</Box>の<Box component="span" fontSize={{xs:30, md:45}} pl={1} pr={1}>プロ集団</Box>に<Box component="span" fontSize={{xs:30, md:45}} pl={1} pr={1}>お任せ</Box>ください！</Box>
                    <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={2} mb={2}></Box>

                    <Box component="h2" fontSize={{xs:16, md:16}} mt={2}>助成金申請に必要な下記全て、助成金コーディネートでサポートできます</Box>

                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" gap={3} mt={10} color="white">

                        <Stack alignItems="center" flexBasis="30%">
                            <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="#4771DF">
                                <Box  fontSize={50} fontWeight="bold" >1</Box>
                            </Stack>
                            <Stack alignItems="center" backgroundColor="#4771DF" borderRadius={3} p={2} pt={4} flex={1} width="100%">
                                <Box component="h3" fontWeight="bold" mb={2} zIndex={1} flex={1}>
                                    <Box fontSize={22}>助成金<Box component="span" fontSize={14} pl={1} pr={1}>を</Box>見つける</Box>
                                </Box>
                                <Box backgroundColor="white" borderRadius={2} width="100%">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/preparation_01.png"} alt="preparation_01" width="100%" ></Box>
                                </Box>
                                
                            </Stack>
                        </Stack>

                        <Stack alignItems="center" flexBasis="30%">
                            <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="#4771DF">
                                <Box  fontSize={50} fontWeight="bold">2</Box>
                            </Stack>
                            <Stack alignItems="center" backgroundColor="#4771DF" borderRadius={3} p={2} pt={4} flex={1} width="100%">
                                <Box component="h3" fontWeight="bold" mb={2} zIndex={1} flex={1}>
                                    <Box fontSize={22}>労務帳簿<Box component="span" fontSize={14} pl={1} pr={1}>の</Box>整備・</Box>
                                    <Box fontSize={14}>届出・維持管理</Box>
                                </Box>
                                <Box backgroundColor="white" borderRadius={2} width="100%">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/preparation_02.png"} alt="preparation_02" width="100%"></Box>
                                </Box>
                                
                            </Stack>
                        </Stack>

                        <Stack alignItems="center" flexBasis="30%">
                            <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="#4771DF">
                                <Box  fontSize={50} fontWeight="bold">3</Box>
                            </Stack>
                            <Stack alignItems="center" backgroundColor="#4771DF" borderRadius={3} p={2} pt={4} flex={1} width="100%">
                                <Box component="h3" fontWeight="bold" mb={2} zIndex={1} flex={1}>
                                    <Box fontSize={22}>助成金<Box component="span" fontSize={14} pl={1} pr={1}>の</Box>申請書類</Box>
                                    <Box fontSize={14}>の作成・提出</Box>
                                </Box>
                                <Box backgroundColor="white" borderRadius={2} width="100%">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/preparation_03.png"} alt="preparation_03" width="100%" ></Box>
                                </Box>
                                
                            </Stack>
                        </Stack>
                        
                    </Stack>
                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" gap={3} mt={3} color="white">

                        <Stack alignItems="center" flexBasis="30%">
                            <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="#4771DF">
                                <Box  fontSize={50} fontWeight="bold" >4</Box>
                            </Stack>
                            <Stack alignItems="center" backgroundColor="#4771DF" borderRadius={3} p={2} pt={4} flex={1} >
                                <Box component="h3" fontWeight="bold" mb={2} zIndex={1} flex={1}>
                                    <Box fontSize={22}>労働局<Box component="span" fontSize={14} pl={1} pr={1}>等からの</Box></Box>
                                    <Box fontSize={22}>質疑応答</Box>
                                </Box>
                                <Box backgroundColor="white" borderRadius={2} width="100%">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/preparation_04.png"} alt="preparation_04" width="100%"></Box>
                                </Box>
                                
                            </Stack>
                        </Stack>

                        <Stack alignItems="center" flexBasis="30%" >
                            <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="#4771DF">
                                <Box  fontSize={50} fontWeight="bold">5</Box>
                            </Stack>
                            <Stack alignItems="center" backgroundColor="#4771DF" borderRadius={3} p={2} pt={4} flex={1} width="100%">
                                <Box component="h3" fontWeight="bold" mb={2} zIndex={1} flex={1}>
                                    <Box fontSize={22}>これら全て<Box component="span" fontSize={14} pl={1} pr={1}>の</Box></Box>
                                    <Box fontSize={22}>スケジュール管理</Box>
                                </Box>
                                <Box backgroundColor="white" borderRadius={2} width="100%">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/preparation_05.png"} alt="preparation_05" width="100%"></Box>
                                </Box>
                                
                            </Stack>
                        </Stack>

                    </Stack>
                    
                </Box>
            </Box>

            <Box component="section" backgroundColor="#D1EAFE" pb={10}>
                <Box className='section-content' >

                    <Box margin="0 auto" mt={-1} width="30%" height={{xs:"50px",md:"100px"}} sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)"}} backgroundColor="white" ></Box>

                    <Stack component="h2" mt={10} alignItems="center" color="#4771DF" fontWeight="bold" flexDirection="row" >
                        <Box fontSize={{xs:50,md:100}} lineHeight={1}>01</Box>
                        <Box component="span" fontSize={{xs:20,md:30}}>助成金を見つける</Box>
                    </Stack>
                    <Stack flexDirection={{xs:"column",md:"row"}} alignItems="center" p={{xs:1,md:5}} flexBasis="25%" width="100%" backgroundColor="white" borderRadius={2}>

                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/preparation_01.png"} alt="preparation_01"  width="100%" maxWidth="500px"></Box>

                        <Stack flex={1} gap={2}>
                            <Stack flexDirection="row" sx={before_checkMark_pink}>貴社が申請可能な助成金をご提案</Stack>
                            <Stack flexDirection="row" sx={before_checkMark_pink}>年度毎に発表される助成金をご案内</Stack>
                            <Stack flexDirection="row" sx={before_checkMark_pink}>緊急発表される助成金など最新情報をお知らせ</Stack>

                            <Stack justifyContent="center" alignItems="center" mt={5}>
                                <Box fontSize={{xs:14, md:16}} fontWeight="bold" mb={1} color="#ED346C">自社が使えそうな助成金を診断してもらいたい</Box>
                                <ConversionButtonA  fontSize={{xs:20, md:30}} >無料<Box component="span" ml={1} mr={1} fontSize={{xs:16, md:20}}>で</Box>助成金診断<Box component="span" ml={1} fontSize={{xs:16, md:20}}>する</Box></ConversionButtonA>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack component="h2" mt={5} alignItems="center" color="#4771DF" fontWeight="bold" flexDirection="row" >
                        <Box fontSize={{xs:50,md:100}} lineHeight={1}>02</Box>
                        <Box component="span" fontSize={{xs:20,md:30}}>労務帳簿 の 整備・届出・維持管理</Box>
                    </Stack>
                    <Box p={{xs:1,md:5}} backgroundColor="white" borderRadius={2}>
                        <Stack flexDirection={{xs:"column",md:"row"}} alignItems="center" width="100%">

                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/preparation_02.png"} alt="preparation_02" width="100%" maxWidth={500}></Box>

                            <Stack flex={1} gap={2}>
                                <Stack flexDirection="row" sx={before_checkMark_pink}>「法令遵守」「貴社独自の経営方針」「助成金の受給要件」などの、複数の視点で専門家がコンサル</Stack>
                                <Stack flexDirection="row" sx={before_checkMark_pink}>貴社の労働環境やご意向をヒアリングし一社一社に最適な帳簿を整備</Stack>
                                <Stack flexDirection="row" sx={before_checkMark_pink}>各帳簿間の「整合」も取り、しっかり整備</Stack>
                                <Stack flexDirection="row" sx={before_checkMark_pink}>整備された帳簿で助成金の合格ラインを突破！</Stack>
                                <Stack flexDirection="row" sx={before_checkMark_pink}>法律が変わった場合の対応までできるので、維持管理も安心！</Stack>
                            </Stack>
                        </Stack>

                        
                        <Box backgroundColor="#EBEBEB" mt={10} p={{xs:2,md:10}} pt={{xs:5,md:10}} pb={{xs:5,md:10}} textAlign="center" position="relative" fontWeight="bold">
                            <Stack component="h2" flexDirection="row" justifyContent="center" color="white" textAlign="center" sx={{transform:"translateY(-50%)"}} position="absolute" left={0} right={0} top={0}>
                                <Box component="h2" p={1} width={{xs:"100%",md:"70%"}} backgroundColor="#4771DF" color="white" fontSize={{xs:16, md:16}} fontWeight="bold" sx={{clipPath:"polygon(0 50%, 15px 0, calc(100% - 15px) 0, 100% 50%, calc(100% - 15px) 100%, 15px 100%)"}}>
                                    <Box component="span" fontSize={{xs:25, md:30}} pl={1} pr={1}>労働法令上</Box>も、<Box component="span" fontSize={{xs:25, md:30}} pl={1} pr={1}>助成金申請上</Box>も<Box component="span" fontSize={{xs:25, md:30}} pl={1} pr={1}>必要</Box>な<Box component="span" fontSize={{xs:25, md:30}} pl={1} pr={1}>帳簿</Box>
                                </Box>
                            </Stack>

                            <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" color="white" gap={{xs:5,md:7}} mt={{xs:5,md:0}}>

                                <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} flexBasis="30%" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px">
                                    <Box flex={1} component="h3" fontSize={{xs:20, md:25}} p={1} backgroundColor="#4771DF" width="100%">就業規則</Box>
                                    <Box p={1} backgroundColor="white">
                                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/labour-management_01_kisoku.png"} alt="labour-management_01_kisoku" width="100%" ></Box>
                                    </Box>                                
                                </Stack>

                                <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} flexBasis="30%" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px">
                                    <Box flex={1} component="h3" fontSize={{xs:20, md:25}} p={1} backgroundColor="#4771DF" width="100%">賃金規程</Box>
                                    <Box p={1} backgroundColor="white">
                                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/labour-management_02_Chingin.png"} alt="labour-management_02_Chingin" width="100%" ></Box>
                                    </Box>                                
                                </Stack>

                                <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} flexBasis="30%" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px">
                                    <Box flex={1} component="h3" fontSize={{xs:20, md:25}} p={1} backgroundColor="#4771DF" width="100%">育児介護休業規程</Box>
                                    <Box p={1} backgroundColor="white">
                                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/labour-management_03_ikuji.png"} alt="labour-management_03_ikuji" width="100%" ></Box>
                                    </Box>                                
                                </Stack>

                            </Stack>

                            <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" color="white" gap={{xs:5,md:7}} mt={{xs:5,md:3}}>

                                <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} flexBasis="30%" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px">
                                    <Box flex={1} component="h3" fontSize={{xs:20, md:25}} p={1} backgroundColor="#4771DF" width="100%">雇用契約書</Box>
                                    <Box p={1} backgroundColor="white">
                                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/labour-management_04_koyou.png"} alt="labour-management_04_koyou" width="100%" ></Box>
                                    </Box>                                
                                </Stack>

                                <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} flexBasis="30%" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px">
                                    <Box flex={1} component="h3" fontSize={{xs:20, md:25}} p={1} backgroundColor="#4771DF" width="100%">36協定</Box>
                                    <Box p={1} backgroundColor="white">
                                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/labour-management_05_36.png"} alt="labour-management_05_36" width="100%" ></Box>
                                    </Box>                                
                                </Stack>

                                <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} flexBasis="30%" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px">
                                    <Box flex={1} component="h3" fontSize={{xs:20, md:25}} p={1} backgroundColor="#4771DF" width="100%">その他 労使協定</Box>
                                    <Box p={1} backgroundColor="white">
                                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/labour-management_06_others.png"} alt="labour-management_06_others" width="100%" ></Box>
                                    </Box>                                
                                </Stack>

                            </Stack>

                            <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" color="white" gap={{xs:5,md:7}} mt={{xs:5,md:3}}>

                                <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} flexBasis="30%" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px">
                                    <Box flex={1} component="h3" fontSize={{xs:20, md:25}} p={1} backgroundColor="#4771DF" width="100%">賃金台帳</Box>
                                    <Box p={1} backgroundColor="white">
                                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/labour-management_07_daicho.png"} alt="labour-management_07_daicho" width="100%" ></Box>
                                    </Box>                                
                                </Stack>

                                <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} flexBasis="30%" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px">
                                    <Box flex={1} component="h3" fontSize={{xs:20, md:25}} p={1} backgroundColor="#4771DF" width="100%">出勤簿</Box>
                                    <Box p={1} backgroundColor="white">
                                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/labour-management_08_syukkin.png"} alt="labour-management_08_syukkin" width="100%" ></Box>
                                    </Box>                                
                                </Stack>

                                <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} flexBasis="30%" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px">
                                    <Box flex={1} component="h3" fontSize={{xs:20, md:25}} p={1} backgroundColor="#4771DF" width="100%">労働者名簿</Box>
                                    <Box p={1} backgroundColor="white">
                                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/labour-management_09_meibo.png"} alt="labour-management_09_meibo" width="100%" ></Box>
                                    </Box>                                
                                </Stack>

                            </Stack>

                            <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" mt={10} textAlign="left" fontSize={{xs:18, md:20}} fontWeight="bold">

                                <Stack  flexBasis="30%" gap={1}>
                                    <Stack flexDirection="row" alignItems="center" sx={before_checkMark_blue}>各帳簿間で整合</Stack>
                                    <Stack flexDirection="row" alignItems="center" sx={before_checkMark_blue}>労働法令に準じた整備</Stack>
                                </Stack>

                                <Stack  flexBasis="30%" gap={1} mt={{xs:1,md:0}}>
                                    <Stack flexDirection="row" alignItems="center" sx={before_checkMark_blue}>受給要件に即した整備</Stack>
                                    <Stack flexDirection="row" alignItems="center" sx={before_checkMark_blue}>法改正に伴う対応</Stack>
                                </Stack>
                                
                            </Stack>
                        </Box>

                        
                        <Box backgroundColor="#EBEBEB" mt={10} p={{xs:2,md:10}} pt={{xs:5,md:10}} pb={{xs:5,md:10}} textAlign="center" position="relative">
                            <Stack component="h2" flexDirection="row" justifyContent="center" color="white" textAlign="center" sx={{transform:"translateY(-50%) "}} position="absolute" left={0} right={0} top={0}>
                                <Box component="h2" p={1} width={{xs:"100%",md:"70%"}} backgroundColor="#4771DF" color="white" fontSize={{xs:16, md:16}} fontWeight="bold" sx={{clipPath:"polygon(0 50%, 15px 0, calc(100% - 15px) 0, 100% 50%, calc(100% - 15px) 100%, 15px 100%)"}}>
                                    <Box component="span" fontSize={{xs:25, md:30}} pl={1} pr={1}>2つの目線</Box>で<Box component="span" fontSize={{xs:25, md:30}} pl={1} pr={1}>労務帳簿</Box>を<Box component="span" fontSize={{xs:25, md:30}} pl={1} pr={1}>整備</Box>します
                                </Box>
                                {/* <Box display="inline-block" width="30px" sx={{clipPath:"polygon(0 50%, 100% 0, 100% 100%)", transform:"translateX(1px)"}} backgroundColor="#4771DF"></Box>
                                <Box p={1} minWidth="70%" backgroundColor="#4771DF"  fontSize={{xs:16, md:16}} fontWeight="bold"><Box component="span" fontSize={{xs:25, md:30}} pl={1} pr={1}>2つの目線</Box>で<Box component="span" fontSize={{xs:25, md:30}} pl={1} pr={1}>労務帳簿</Box>を<Box component="span" fontSize={{xs:25, md:30}} pl={1} pr={1}>整備</Box>します</Box>
                                <Box display="inline-block" width="30px" sx={{clipPath:"polygon(0 0, 100% 50%, 0 100%)", transform:"translateX(-1px)"}} backgroundColor="#4771DF"></Box> */}
                            </Stack>
                            
                            <Stack alignItems="center" mt={{xs:5,md:0}}>
                                <Box backgroundColor="white" p={2}>
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/two-perspectives_01.png"} alt="two-perspectives_01" width="100%" maxWidth={500}></Box>
                                </Box>
                            </Stack>

                            <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="space-between" mt={5}  p={{xs:1,md:2}} gap={2}>

                                <Box  flexBasis="49%">
                                    <Stack alignItems="center">
                                        <Box fontSize={{xs:30,md:50}} color="white" backgroundColor="#4771DF" p={1} pl={2} pr={2} lineHeight={1}>1</Box>
                                    </Stack>

                                    <Box component="h2" fontSize={{xs:16,md:20}} mt={2} fontWeight="bold"><Box component="span" color="#ED346C" fontSize={{xs:30,md:40}} mr={1}>法令遵守目線</Box>での整備</Box>
                                    <Box component="img" mt={2} src={process.env.PUBLIC_URL+"/static/media/LP/two-perspectives_02.png"} alt="two-perspectives_02" width="100%"></Box>
                                </Box>


                                <Box flexBasis={1} backgroundColor="#4771DF" ></Box>

                                <Box  flexBasis="49%">
                                    <Stack alignItems="center">
                                        <Box fontSize={{xs:30,md:50}} color="white" backgroundColor="#4771DF" p={1} pl={2} pr={2} lineHeight={1}>2</Box>
                                    </Stack>

                                    <Box component="h2" fontSize={{xs:16,md:20}} mt={2} fontWeight="bold"><Box component="span" color="#ED346C" fontSize={{xs:30,md:40}} mr={1}>受給要件目線</Box>での整備</Box>
                                    <Box component="img" mt={2} src={process.env.PUBLIC_URL+"/static/media/LP/two-perspectives_03.png"} alt="two-perspectives_03" width="100%"></Box>
                                    
                                </Box>
                                
                            </Stack>
                        </Box>
                    </Box>

                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="start" mt={5} gap={2}>
                        <Box  flexBasis="50%">
                            <Stack component="h2"  alignItems="center" color="#4771DF" fontWeight="bold" flexDirection="row" >
                                <Box fontSize={{xs:50,md:100}} lineHeight={1}>03</Box>
                                <Box component="span" fontSize={{xs:20,md:30}}>助成金の申請書類の作成・提出</Box>
                            </Stack>
                            <Box p={{xs:1,md:5}} backgroundColor="white" textAlign="center" borderRadius={2}>
                                <Box textAlign="center">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/preparation_03.png"} alt="preparation_03" width="100%" maxWidth={400}></Box>
                                </Box>
                                <Box flex={1}>
                                    <Stack flexDirection="row" sx={before_checkMark_pink}>助成金専門の社労士が対応</Stack>
                                    <Stack flexDirection="row" sx={before_checkMark_pink}>難しく膨大な申請書類の作成をサポート</Stack>
                                </Box>
                            </Box>
                            
                        </Box>

                        <Box  flexBasis="50%">
                            <Stack component="h2"  alignItems="center" color="#4771DF" fontWeight="bold" flexDirection="row" >
                                <Box fontSize={{xs:50,md:100}} lineHeight={1}>04</Box>
                                <Box component="span" fontSize={{xs:20,md:30}}>労働局等からの質疑応答</Box>
                            </Stack>
                            <Box p={{xs:1,md:5}} backgroundColor="white" borderRadius={2}>
                                <Box textAlign="center">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/preparation_04.png"} alt="preparation_04" width="100%" maxWidth={400}></Box>
                                </Box>
                                <Box flex={1}>
                                    <Stack flexDirection="row"  sx={before_checkMark_pink}>申請書類の作成、届出のみならず、労働局等からの質疑応答までしっかり代行</Stack>
                                </Box>
                              
                            </Box>
                            
                        </Box>
                    </Stack>
                    
                    <Stack component="h2"  alignItems="center" color="#4771DF" fontWeight="bold" flexDirection="row" mt={5}>
                        <Box fontSize={{xs:50,md:100}} lineHeight={1}>05</Box>
                        <Box component="span" fontSize={{xs:20,md:30}}>これら全て の スケジュール管理</Box>
                    </Stack>
                    <Stack flexDirection={{xs:"column",md:"row"}} alignItems="center" p={{xs:1,md:5}} flexBasis="25%" width="100%" backgroundColor="white" borderRadius={2}>

                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/preparation_05.png"} alt="preparation_05" width="100%" maxWidth={500}></Box>

                        <Stack flex={1} gap={2}>
                            <Stack flexDirection="row" sx={before_checkMark_pink}>貴社専属の助成金コンサルタントが進捗管理</Stack>
                            <Stack flexDirection="row" sx={before_checkMark_pink}>それに加え、マイページにていつでも進捗状況を把握可能</Stack>
                            <Stack flexDirection="row" sx={before_checkMark_pink}>進捗状況が確認できるので、不安なく手続きを進められる</Stack>
                        </Stack>
                    </Stack>




                    
                    
                </Box>
            </Box>

            <Box component="section" backgroundColor="white" pb={10} pt={10} textAlign="center">
                <Box className='section-content' >
                    <Box component="h1" fontSize={{xs:20, md:30}} fontWeight="bold" mt={2}><Box component="span" fontSize={{xs:30, md:45}} pl={1} pr={1}>安心</Box>の<Box component="span" fontSize={{xs:30, md:45}} pl={1} pr={1}>サポート体制</Box></Box>
                    <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={2} mb={2}></Box>

                    <Box component="h2" fontSize={{xs:16, md:16}} mt={2}>
                        <Box>貴社専属のチームを構成し、各分野の専門家がサポートします。</Box>
                        <Box>助成金受給に向けたプロセスで、しっかりと 労務整備 を行い、労務リスク対策 までしっかり行います。</Box>
                    </Box>

                    <Box mt={10} component="img" src={process.env.PUBLIC_URL+"/static/media/LP/support-system.png"} alt="support-system"  width="100%" ></Box>

                    <Box fontSize={{xs:16, md:20}} fontWeight="bold" mt={10}>ご相談内容に応じて 貴社にとっての<Box component="span" color="#ED346C" fontSize={{xs:25, md:30}} ml={1} mr={1}>最適解</Box>をご用意します。</Box>

                </Box>
            </Box>

            {/* <Box component="section" backgroundColor="#EBEBEB" pb={10} pt={10} textAlign="center">
                <Box className='section-content' >
                    <Stack alignItems="center">
                        <Link href={"/"} target="_blank" backgroundColor="#4771DF" sx={{color:"white"}} fontWeight="bold" textAlign="center" mt={2} p={5} maxWidth={"95%"} width={400} borderRadius={2}>運営会社TRIPORTグループ</Link>
                    </Stack>
                </Box>
            </Box> */}

            <Box component="section" backgroundColor="white" pb={10}>
                <Box className='section-content' textAlign="center">
                    
                    <Stack fontSize={30} flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" alignItems="center" gap={3}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_award1.png"} alt="TOP_award1" width="100%" maxWidth={250}></Box>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_award2.png"} alt="TOP_award2" width="100%" maxWidth={250}></Box>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_award3.png"} alt="TOP_award3" width="100%" maxWidth={250}></Box>
                    </Stack>
                    <Box fontSize={11} mt={6} textAlign="left">
                        <Box>※1 2024年3月現在</Box>
                        <Box>※2 日本HRチャレンジ大賞は、助成金コーディネートの元となるサービスである「クラウド社労士コモン」が受賞しました。</Box>
                        <Box>※3 厚生労働省『輝くテレワーク賞』特別奨励賞に受賞、総務省『テレワーク先駆者百選』に選出、東京都『東京ライフワークバランス認定企業』に認定、東京都『東京都女性活躍推進大賞』優秀賞を受賞、など</Box>
                    </Box>

                    <Box component="h1" fontWeight="bold" fontSize={{xs:30, md:40}} mt={10}>
                        <Box>助成金<Box component="span" fontSize={{xs:20, md:25}} ml={1} mr={1}>を</Box>フル活用<Box component="span" fontSize={{xs:20, md:25}} ml={1} mr={1}>して</Box></Box>
                        <Box>社員が輝く経営サイクル<Box component="span" fontSize={{xs:20, md:25}} ml={1}>をつくる。</Box></Box>
                    </Box>

                    <Box fontWeight="bold" fontSize={{xs:35, md:65}} mt={5} mb={5}>「助成金コーディネート」</Box>

                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_J_coordinate.png"} alt="TOP_J_coordinate" width="100%" maxWidth={500}></Box>

                    <Box fontSize={{xs:20, md:30}} fontWeight="bold" mt={10}>
                        <Box><AccentBox>多数実績</AccentBox><Box component="span" fontSize={{xs:16, md:20}} ml={1} mr={1}>の中で培ってきた</Box><Box component="br" display={{xs:"block",md:"none"}}></Box><AccentBox>ノウハウ・ナレッジ。</AccentBox></Box>
                        <Box><Box component="span" fontSize={{xs:16, md:20}} ml={1} mr={1}>労務・助成金の</Box><AccentBox>プロ集団</AccentBox><Box component="span" fontSize={{xs:16, md:20}} ml={1} mr={1}>が、継続的に助成金をフル活用できる</Box><AccentBox>しくみづくり</AccentBox><Box component="span" fontSize={{xs:16, md:20}} ml={1} >をサポートします。</Box></Box>
                    </Box>
                    
                </Box>
            </Box>

            <Box component="section" backgroundColor="#4771DF" textAlign="center" pt={10} pb={10}>
                <Box className='section-content' textAlign="center" color="white">

                    <Box fontSize={{xs:20, md:30}} fontWeight="bold">
                        <Box>会社の<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>労務リスク</Box>と、<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>助成金</Box>のお悩み、</Box>
                        <Box><Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>「助成金コーディネート」</Box>で<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>同時に解決</Box>しませんか？！</Box>
                    </Box>
                    
                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" mt={10} gap={5}>
                        
                        <Stack alignItems="center" gap={4}>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA1.png"} alt="CTA1" width="100%" maxWidth={300}></Box>

                            <Box width="100%">
                                <Box fontSize={16} color="white">たった<Box component="span" fontSize={30}>５</Box>分で<Box component="span" fontSize={30}>カンタン</Box>!</Box>
                                <ConversionButtonA display="block" fontSize={{xs:20, md:30}} >助成金診断<Box component="span" ml={1} fontSize={{xs:16, md:20}}>する</Box></ConversionButtonA>
                            </Box>
                        </Stack>
                        
                        
                        <Stack alignItems="center" gap={4}>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA2.png"} alt="CTA2" width="100%" maxWidth={300}></Box>
                            <Stack flex={1} justifyContent="end" width="100%">
                                
                                <ConversionButtonB display="block" fontSize={{xs:20, md:30}}><Box component="span"></Box>プロに相談<Box component="span" ml={1} fontSize={{xs:16, md:20}}>する</Box></ConversionButtonB>
                                
                            </Stack>
                        </Stack>
                        
                    </Stack>
                </Box>
            </Box>

            <Footer/>
        </>
    )
}

export default Service;