import React from 'react';
import {Box,Stack} from '@mui/material'
import ConversionButtonA from "../../Components/ConversionButtonA";
import ConversionButtonB from "../../Components/ConversionButtonB";
import Header from './Header';
import Footer from './Footer';
import AccentBox from '../../Components/AccentBox';

function Flow(){

    const before_checkMark_blue={
        '&::before':{
            content:'""', 
            display:"inline-block", 
            verticalAlign:"middle", 
            mr:"12px", 
            width:"20px", 
            height:"20px", 
            minWidth:"20px",
            background:"url("+process.env.PUBLIC_URL+"/static/media/LP/checkbox1.png) no-repeat",backgroundSize:"contain"
        }
    }

    const before_checkMark_pink={
        '&::before':{
            content:'""', 
            display:"inline-block", 
            verticalAlign:"middle", 
            mr:"12px", 
            width:"30px", 
            height:"30px", 
            minWidth:"30px",
            background:"url("+process.env.PUBLIC_URL+"/static/media/LP/checkbox2.png) no-repeat",backgroundSize:"contain"
        }
    }

    return (
        <>
            <Header/>

            <Box component="section" backgroundColor="#D1EAFE" pt={30} pb={20}>
                <Box className='section-content' >
                    <Box textAlign="center" fontSize={{xs:20, md:30}} fontWeight="bold">ご利用までの流れ</Box>
                </Box>
            </Box>

            <Box component="section" backgroundColor="white" textAlign="center" pt={10} pb={10}>
                <Box className='section-content' textAlign="center" color="white">
                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" gap={3}>

                        <Stack alignItems="center" flexBasis="25%" width="100%">
                            <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="#4771DF" color="white" >
                                <Box fontSize={50} fontWeight="bold" lineHeight={1} textAlign="center"><Box fontSize={16}>STEP</Box>1</Box>
                            </Stack>
                            <Stack alignItems="center" backgroundColor="#4771DF" borderRadius={5} p={2} pt={5} flex={1} width="100%">
                                <Stack fontSize={25} fontWeight="bold" mb={2} flex={1} justifyContent="center">
                                    <Box>無料コンサルの</Box>
                                    <Box>ご予約</Box>
                                </Stack>
                                <Box backgroundColor="white" borderRadius={2}>
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/flow_01.png"} alt="flow_01"  width="100%"></Box>
                                </Box>
                                
                            </Stack>
                        </Stack>

                        <Stack alignItems="center" flexBasis="25%" width="100%">
                            <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="#4771DF" color="white">
                            <Box fontSize={50} fontWeight="bold" lineHeight={1} textAlign="center"><Box fontSize={16}>STEP</Box>2</Box>
                            </Stack>
                            <Stack alignItems="center" backgroundColor="#4771DF" borderRadius={5} p={2} pt={5} flex={1} width="100%">
                                <Stack fontSize={25} fontWeight="bold" mb={2} flex={1} justifyContent="center">
                                    <Box>助成金</Box>
                                    <Box>個別無料コンサル</Box>
                                </Stack>
                                <Box backgroundColor="white" borderRadius={2}>
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/flow_02.png"} alt="flow_02"  width="100%"></Box>
                                </Box>
                                
                            </Stack>
                        </Stack>

                        <Stack alignItems="center" flexBasis="25%" width="100%">
                            <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="#4771DF" color="white">
                            <Box fontSize={50} fontWeight="bold" lineHeight={1} textAlign="center"><Box fontSize={16}>STEP</Box>3</Box>
                            </Stack>
                            <Stack alignItems="center" backgroundColor="#4771DF" borderRadius={5} p={2} pt={5} flex={1} width="100%">
                                <Stack fontSize={25} fontWeight="bold" mb={2} flex={1} justifyContent="center">
                                    <Box>お申込</Box>
                                    <Box fontSize={15}>（任意）</Box>
                                </Stack>
                                <Box backgroundColor="white" borderRadius={2}>
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/flow_03.png"} alt="flow_03"  width="100%"></Box>
                                </Box>
                                
                            </Stack>
                        </Stack>

                        <Stack alignItems="center" flexBasis="25%" width="100%">
                            <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="#4771DF" color="white">
                            <Box fontSize={50} fontWeight="bold" lineHeight={1} textAlign="center"><Box fontSize={16}>STEP</Box>4</Box>
                            </Stack>
                            <Stack alignItems="center" backgroundColor="#4771DF" borderRadius={5} p={2} pt={5} flex={1} width="100%">
                                <Stack fontSize={25} fontWeight="bold" mb={2} flex={1} justifyContent="center">
                                    <Box>サービス提供開始</Box>
                                    <Box fontSize={15}>（任意）</Box>
                                </Stack>
                                <Box backgroundColor="white" borderRadius={2}>
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/flow_04.png"} alt="flow_04"  width="100%" ></Box>
                                </Box>
                                
                            </Stack>
                        </Stack>

                    
                    </Stack>

                    <Stack flexDirection="row" mt={5} alignItems="center">
                        <Stack flex={1} flexDirection="row" height={50} backgroundColor="#4771DF" justifyContent="center" alignItems="center" fontSize={18} color="white">最短<Box component="span" fontSize={25} ml={1} mr={1}>１営業日</Box>以内</Stack>
                        <Box width="15%" maxWidth={80} ml={-1} backgroundColor="#4771DF" height="100px" sx={{clipPath:"polygon(0 0, 100% 50%, 0 100%)"}}></Box>
                    </Stack>
                </Box>
            </Box>

            <Box component="section" backgroundColor="#D1EAFE" pb={10}>
                <Box className='section-content' >
                    
                    <Box margin="0 auto" mt={-1} width="30%" height={{xs:"50px",md:"100px"}} sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)",transform:"translateY(-1px)"}} backgroundColor="white" ></Box>
                    
                    <Stack gap={3} mt={5}>
                        <Stack flexDirection={{xs:"column",md:"row"}} alignItems="center" backgroundColor="white" borderRadius={5} p={2} gap={2}>
                            
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/flow_01.png"} alt="flow_01" width="100%" maxWidth={500}></Box>
                            
                            <Stack flex={1} justifyContent="start" gap={2}>
                                <Box mb={2}>
                                    <Box fontSize={{xs:20, md:20}} fontWeight="bold" color="#4771DF">STEP</Box>
                                    <Stack flexDirection="row" fontSize={{xs:18, md:25}} fontWeight="bold" color="#4771DF" alignItems="center" ml={1} gap={1}><Box fontSize={60} lineHeight={0.8} mr={1}>1</Box>無料コンサルのご予約</Stack>
                                </Box>

                                <Stack flexDirection="row" sx={before_checkMark_blue} textAlign="left" fontWeight="bold" mt={2}><Box fontSize={{xs:18, md:18}}>「無料でプロに相談」のボタンよりご予約ください</Box></Stack>
                                
                                {/* <ConversionButtonB maxWidth="400px" fontSize={{xs:20, md:30}} >無料<Box component="span" margin="0 4px"  fontSize={{xs:16, md:25}}>で</Box>プロに相談<Box component="span" margin="0 4px" fontSize={25}>する</Box></ConversionButtonB> */}
                                <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="start" textAlign="center" gap={3} mt={2} >
                                    <Box>
                                        <ConversionButtonB display="block" fontSize={{xs:20, md:30}} >無料<Box component="span" ml={1} mr={1} fontSize={{xs:16, md:20}}>で</Box>プロに相談<Box component="span" ml={1} fontSize={{xs:16, md:20}}>する</Box></ConversionButtonB>
                                    </Box>
                                </Stack>
                                
                                
                            </Stack>
                        </Stack>

                        <Stack flexDirection={{xs:"column",md:"row"}}  alignItems="center" backgroundColor="white" borderRadius={5} p={2} gap={2}>
                            
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/flow_02.png"} alt="flow_02" width="100%" maxWidth={500}></Box>
                            
                            <Stack flex={1} justifyContent="start" gap={2}>
                                <Box mb={2}>
                                    <Box fontSize={{xs:20, md:20}} fontWeight="bold" color="#4771DF" >STEP</Box>
                                    <Stack flexDirection="row" fontSize={{xs:18, md:25}} fontWeight="bold" color="#4771DF" alignItems="center" ml={1} gap={1}><Box fontSize={60} lineHeight={0.8} mr={1}>2</Box>助成金個別無料コンサル</Stack>
                                </Box>
                                <Stack flexDirection="row" sx={before_checkMark_blue} textAlign="left" fontWeight="bold" mt={2}><Box fontSize={{xs:18, md:18}}>今すぐ活用できる助成金を、個別ヒアリングの上その場で解説します</Box></Stack>
                                <Stack flexDirection="row" sx={before_checkMark_blue} textAlign="left" fontWeight="bold" ><Box fontSize={{xs:18, md:18}}>貴社のご状況にあったサービス・プランをご提案します</Box></Stack>
                                
                            </Stack>
                        </Stack>

                        <Stack flexDirection={{xs:"column",md:"row"}}  alignItems="center" backgroundColor="white" borderRadius={5} p={2} gap={2}>
                            
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/flow_03.png"} alt="flow_03" width="100%" maxWidth={500}></Box>
                            
                            <Stack flex={1} justifyContent="start" gap={2}>
                                <Box mb={2}>
                                    <Box fontSize={{xs:20, md:20}} fontWeight="bold" color="#4771DF" >STEP</Box>
                                    <Stack flexDirection="row" fontSize={{xs:18, md:25}} fontWeight="bold" color="#4771DF" alignItems="center" ml={1} gap={1}><Box fontSize={60} lineHeight={0.8} mr={1}>3</Box>お申込</Stack>
                                </Box>
                                <Stack flexDirection="row" sx={before_checkMark_blue} textAlign="left" fontWeight="bold" mt={2}><Box fontSize={{xs:18, md:18}}>Web上で簡単にお申し込みいただけます</Box></Stack>
                                
                            </Stack>
                        </Stack>

                        <Stack flexDirection={{xs:"column",md:"row"}}  alignItems="center" backgroundColor="white" borderRadius={5} p={2} gap={2}>
                            
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/flow_04.png"} alt="flow_04" width="100%" maxWidth={500}></Box>
                            
                            <Stack flex={1} justifyContent="start" gap={2}>
                                <Box mb={2}>
                                    <Box fontSize={{xs:20, md:20}} fontWeight="bold" color="#4771DF" >STEP</Box>
                                    <Stack flexDirection="row" fontSize={{xs:18, md:25}} fontWeight="bold" color="#4771DF" alignItems="center" ml={1} gap={1}><Box fontSize={60} lineHeight={0.8} mr={1}>4</Box>サービス提供開始</Stack>
                                </Box>
                                <Stack flexDirection="row" sx={before_checkMark_blue} textAlign="left" fontWeight="bold" mt={2}><Box fontSize={{xs:18, md:18}}>貴社専属チームを構成します</Box></Stack>
                                <Stack flexDirection="row" sx={before_checkMark_blue} textAlign="left"fontWeight="bold" ><Box fontSize={{xs:18, md:18}}>
                                        <Box>先生と初回のお打合せをするとともに</Box>
                                        <Box>労務周りの整備前のアンケートにご回答いただきます</Box>
                                    </Box>
                                </Stack>
                                <Stack flexDirection="row" sx={before_checkMark_blue} textAlign="left" fontWeight="bold"><Box fontSize={{xs:18, md:18}}>労務周りの整備、助成金選定・申請をスタートします</Box></Stack>
                            
                            </Stack>
                        </Stack>
                    </Stack>
                    
                </Box>
            </Box>

            <Box component="section" backgroundColor="white" pt={10} pb={10}>
                <Box className='section-content' >
                  
                    <Stack flexDirection={{xs:"column-reverse",md:"row"}} justifyContent="space-evenly"  alignItems="center" backgroundColor="#4771DF" color="white" borderRadius={5} p={{xs:2,md:5}}>
                        <Stack justifyContent="space-evenly" gap={1}>
                            <Stack flexDirection="row" sx={before_checkMark_pink} alignItems="center" textAlign="left"><Box fontSize={{xs:16, md:20}} fontWeight="bold" flex={1}>助成金について<Box component="br" display={{xs:"block",md:"none"}}/>１から教えてほしい！</Box></Stack>
                            <Stack flexDirection="row" sx={before_checkMark_pink} alignItems="center" textAlign="left"><Box fontSize={{xs:16, md:20}} fontWeight="bold" flex={1}>自分の会社が使える助成金が<Box component="br" display={{xs:"block",md:"none"}}/>知りたい！</Box></Stack>
                            <Stack flexDirection="row" sx={before_checkMark_pink} alignItems="center" textAlign="left"><Box fontSize={{xs:16, md:20}} fontWeight="bold" flex={1}>今のうちから、<Box component="br" display={{xs:"block",md:"none"}}/>できることを教えて欲しい！</Box></Stack>
                            <Stack flexDirection="row" sx={before_checkMark_pink} alignItems="center" textAlign="left"><Box fontSize={{xs:16, md:20}} fontWeight="bold" flex={1}>助成金を使ったことはあるが、<Box component="br" display={{xs:"block",md:"none"}}/>他の助成金についても知りたい！</Box></Stack>
                        </Stack>
                        <Box width={300} textAlign="center" mt={2}>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA3.png"} alt="CTA3" width="100%" maxWidth={160}></Box>
                        </Box>
                    </Stack>
                    <Box margin="0 auto" mt={-1} width="30%" height={{xs:"50px",md:"100px"}} sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)"}} backgroundColor="#4771DF" ></Box>
                    
                    <Box fontSize={{xs:20, md:30}} fontWeight="bold" color="#ED346C" textAlign="center" mt={5} >まずは、<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>お気軽に</Box>に、<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>お問合せ</Box>ください！</Box>

                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" textAlign="center" gap={3} mt={5} >
                        <Box>
                            <ConversionButtonB display="block" fontSize={{xs:20, md:30}} >無料<Box component="span" ml={1} mr={1} fontSize={{xs:16, md:20}}>で</Box>プロに相談<Box component="span" ml={1} fontSize={{xs:16, md:20}}>する</Box></ConversionButtonB>
                        </Box>
                    </Stack>
                    
                </Box>
            </Box>

            <Box component="section" backgroundColor="#D1EAFE" pt={10} pb={10}>
                <Box className='section-content' textAlign="center">
                    
                    <Stack fontSize={30} flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" alignItems="center" gap={3}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_award1.png"} alt="TOP_award1" width="100%" maxWidth={250}></Box>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_award2.png"} alt="TOP_award2" width="100%" maxWidth={250}></Box>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_award3.png"} alt="TOP_award3" width="100%" maxWidth={250}></Box>
                    </Stack>
                    <Box fontSize={11} mt={6} textAlign="left">
                        <Box>※1 2024年3月現在</Box>
                        <Box>※2 日本HRチャレンジ大賞は、助成金コーディネートの元となるサービスである「クラウド社労士コモン」が受賞しました。</Box>
                        <Box>※3 厚生労働省『輝くテレワーク賞』特別奨励賞に受賞、総務省『テレワーク先駆者百選』に選出、東京都『東京ライフワークバランス認定企業』に認定、東京都『東京都女性活躍推進大賞』優秀賞を受賞、など</Box>
                    </Box>

                    <Box fontWeight="bold" fontSize={{xs:30, md:40}} mt={10}>
                        <Box>助成金<Box component="span" fontSize={{xs:20, md:25}} ml={1} mr={1}>を</Box>フル活用<Box component="span" fontSize={{xs:20, md:25}} ml={1} mr={1}>して</Box></Box>
                        <Box>社員が輝く経営サイクル<Box component="span" fontSize={{xs:20, md:25}} ml={1}>をつくる</Box></Box>
                    </Box>

                    <Box fontWeight="bold" fontSize={{xs:35, md:65}} mt={5} mb={5}>助成金コーディネート</Box>

                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_J_coordinate.png"} alt="TOP_J_coordinate" width="100%" maxWidth={500}></Box>

                    <Box fontSize={{xs:20, md:30}} fontWeight="bold" mt={10}>
                        <Box><AccentBox>多数実績</AccentBox><Box component="span" fontSize={{xs:16, md:20}} ml={1} mr={1}>の中で培ってきた</Box><Box component="br" display={{xs:"block",md:"none"}}></Box><AccentBox>ノウハウ・ナレッジ。</AccentBox></Box>
                        <Box><Box component="span" fontSize={{xs:16, md:20}} ml={1} mr={1}>労務・助成金の</Box><AccentBox>プロ集団</AccentBox><Box component="span" fontSize={{xs:16, md:20}} ml={1} mr={1}>が、継続的に助成金をフル活用できる</Box><AccentBox>しくみづくり</AccentBox><Box component="span" fontSize={{xs:16, md:20}} ml={1} >をサポートします。</Box></Box>
                    </Box>
                    
                </Box>
            </Box>

            <Box component="section" backgroundColor="#4771DF" textAlign="center" pt={10} pb={10}>
                <Box className='section-content' textAlign="center" color="white">
                    <Box fontSize={{xs:20, md:30}}><Box component="span" fontSize={{xs:30, md:45}} mr={1}>助成金、</Box>どうせやるなら<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>「フル活用」</Box>！</Box>
                    
                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" mt={10} gap={5}>
                        
                        <Stack flex={1} alignItems="center" gap={2}>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA1_2.png"} alt="CTA1_2" width="100%" maxWidth={400}></Box>

                            <ConversionButtonA display="block" fontSize={{xs:20, md:30}} >無料<Box component="span" margin="0 4px" fontSize={{xs:16, md:20}}>で</Box>助成金診断<Box component="span" margin="0 4px" fontSize={{xs:16, md:20}}>する</Box></ConversionButtonA>
                        </Stack>
                        
                        <Stack flex={1} alignItems="center" gap={2}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA2_2.png"} alt="CTA2_2" width="100%" maxWidth={400}></Box>
                            <ConversionButtonB display="block" fontSize={{xs:20, md:30}} >無料<Box component="span" ml={1} mr={1} fontSize={{xs:16, md:20}}>で</Box>プロに相談<Box component="span" ml={1} fontSize={{xs:16, md:20}}>する</Box></ConversionButtonB>
                        </Stack>
                        
                    </Stack>
                </Box>
            </Box>

            <Footer/>
        </>
    )
}

export default Flow;