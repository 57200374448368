import React from 'react';
import {Box,Stack} from '@mui/material'
import ConversionButtonA from "../../Components/ConversionButtonA";
import ConversionButtonB from "../../Components/ConversionButtonB";
import Header from './Header';
import Footer from './Footer';


function Prepare(){

    const before_checkMark_blue={
        '&::before':{
            content:'""', 
            display:"inline-block", 
            verticalAlign:"middle", 
            mr:"12px", 
            width:"20px", 
            height:"20px", 
            minWidth:"20px",
            
            background:"url("+process.env.PUBLIC_URL+"/static/media/LP/checkbox1.png) no-repeat",backgroundSize:"contain"
        }
    }

    return (
        <>
            <Header/>

            <Box component="section" backgroundColor="#D1EAFE" pt={30} pb={20}>
                <Box className='section-content' >
                    <Box component="h1" textAlign="center" fontSize={{xs:20, md:30}} fontWeight="bold">助成金申請に必要な準備</Box>
                </Box>
            </Box>

            <Box component="section" backgroundColor="white" pt={10} pb={10}>
                <Box className='section-content' textAlign="center">
                    <Box component="h1" fontSize={{xs:20, md:30}} fontWeight="bold" mt={2}><Box component="span" fontSize={{xs:30, md:45}} pl={1} pr={1}>助成金申請</Box>に必要な<Box component="span" fontSize={{xs:30, md:45}} pl={1} pr={1}>5つ</Box>のこと</Box>
                    <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={2} mb={10}></Box>

                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" gap={3}>

                        <Stack alignItems="center" flexBasis="30%">
                            <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="#D1EAFE">
                                <Box  fontSize={50} fontWeight="bold" >1</Box>
                            </Stack>
                            <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} p={2} pt={4} flex={1} width="100%">
                                <Box component="h3" fontWeight="bold" mb={2} zIndex={1} flex={1}>
                                    <Box fontSize={22}>助成金<Box component="span" fontSize={14} pl={1} pr={1}>を</Box>見つける</Box>
                                </Box>
                                <Box backgroundColor="white" borderRadius={2} width="100%">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/preparation_01.png"} alt="preparation_01" width="100%" ></Box>
                                </Box>
                                
                            </Stack>
                        </Stack>

                        <Stack alignItems="center" flexBasis="30%">
                            <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="#D1EAFE">
                                <Box  fontSize={50} fontWeight="bold">2</Box>
                            </Stack>
                            <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} p={2} pt={4} flex={1} width="100%">
                                <Box component="h3" fontWeight="bold" mb={2} zIndex={1} flex={1}>
                                    <Box fontSize={22}>労務帳簿<Box component="span" fontSize={14} pl={1} pr={1}>の</Box>整備・</Box>
                                    <Box fontSize={14}>届出・維持管理</Box>
                                </Box>
                                <Box backgroundColor="white" borderRadius={2} width="100%">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/preparation_02.png"} alt="preparation_02" width="100%" ></Box>
                                </Box>
                                
                            </Stack>
                        </Stack>

                        <Stack alignItems="center" flexBasis="30%">
                            <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="#D1EAFE">
                                <Box  fontSize={50} fontWeight="bold">3</Box>
                            </Stack>
                            <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} p={2} pt={4} flex={1} width="100%">
                                <Box component="h3" fontWeight="bold" mb={2} zIndex={1} flex={1}>
                                    <Box fontSize={22}>助成金<Box component="span" fontSize={14} pl={1} pr={1}>の</Box>申請書類</Box>
                                    <Box fontSize={14}>の作成・提出</Box>
                                </Box>
                                <Box backgroundColor="white" borderRadius={2} width="100%">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/preparation_03.png"} alt="preparation_03" width="100%"></Box>
                                </Box>
                                
                            </Stack>
                        </Stack>
                        
                    </Stack>
                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" gap={3} mt={3}>

                        <Stack alignItems="center" flexBasis="30%">
                            <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="#D1EAFE">
                                <Box  fontSize={50} fontWeight="bold" >4</Box>
                            </Stack>
                            <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} p={2} pt={4} flex={1} width="100%">
                                <Box component="h3" fontWeight="bold" mb={2} zIndex={1} flex={1}>
                                    <Box fontSize={22}>労働局<Box component="span" fontSize={14} pl={1} pr={1}>等からの</Box></Box>
                                    <Box fontSize={22}>質疑応答</Box>
                                </Box>
                                <Box backgroundColor="white" borderRadius={2} width="100%">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/preparation_04.png"} alt="preparation_04" width="100%" ></Box>
                                </Box>
                                
                            </Stack>
                        </Stack>

                        <Stack alignItems="center" flexBasis="30%" >
                            <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="#D1EAFE">
                                <Box  fontSize={50} fontWeight="bold">5</Box>
                            </Stack>
                            <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} p={2} pt={4} flex={1} width="100%">
                                <Box component="h3" fontWeight="bold" mb={2} zIndex={1} flex={1}>
                                    <Box fontSize={22}>これら全て<Box component="span" fontSize={14} pl={1} pr={1}>の</Box></Box>
                                    <Box fontSize={22}>スケジュール管理</Box>
                                </Box>
                                <Box backgroundColor="white" borderRadius={2} width="100%">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/preparation_05.png"} alt="preparation_05" width="100%" ></Box>
                                </Box>
                                
                            </Stack>
                        </Stack>

                    </Stack>
                    
                </Box>
            </Box>

            <Box component="section" backgroundColor="#EBEBEB" pb={10}>
                <Box className='section-content' textAlign="center">

                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/preparation_02.png"} alt="preparation_02" width="100%" maxWidth={200} mt={10}></Box>

                    <Box maxWidth={{xs:"80%",md:"30%"}} margin="0 auto" mt={3}>
                        <Box backgroundColor="white" p={2} borderRadius={5} textAlign="center" fontWeight="bold" fontSize={20}>労働法令上だけでなく</Box>
                            
                        <Stack justifyContent="center" alignItems="center" position="relative" >
                            <Box width="30px" height="calc(60px / 2)" sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)"}} backgroundColor="white" position="absolute" bottom={-20}></Box>
                        </Stack>
                    </Box>

                    <Box component="h1" fontSize={{xs:20, md:30}} fontWeight="bold" mt={3}><Box component="span" fontSize={{xs:30, md:45}} pl={1} pr={1}>助成金申請上</Box>も必要な労務帳簿</Box>
                    <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={2} mb={2}></Box>

                    <Box component="h1" fontSize={{xs:16, md:16}} mt={2}>助成金申請時に労働局等にチェックされるため、労務帳簿をしっかり整備しておく必要があります。</Box>

                    <Box backgroundColor="#D1EAFE" borderRadius={2} mt={10} p={{xs:2,md:10}} pt={{xs:5}} pb={{xs:5}} color="white">
                        {/* <Stack component="h2" flexDirection="row" justifyContent="center">
                            <Box display="inline-block" width="30px" sx={{clipPath:"polygon(0 50%, 100% 0, 100% 100%)", transform:"translateX(1px)"}} backgroundColor="#4771DF"></Box>
                            <Box p={1} minWidth="70%" backgroundColor="#4771DF" fontSize={{xs:16, md:16}} fontWeight="bold"><Box component="span" fontSize={{xs:25, md:30}} pl={1} pr={1}>労働法令上</Box>も、<Box component="span" fontSize={{xs:25, md:30}} pl={1} pr={1}>助成金申請上</Box>も<Box component="span" fontSize={{xs:25, md:30}} pl={1} pr={1}>必要</Box>な<Box component="span" fontSize={{xs:25, md:30}} pl={1} pr={1}>帳簿</Box></Box>
                            <Box display="inline-block" width="30px" sx={{clipPath:"polygon(0 0, 100% 50%, 0 100%)", transform:"translateX(-1px)"}} backgroundColor="#4771DF"></Box>
                        </Stack> */}

                        <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" gap={{xs:5,md:7}} fontWeight="bold">

                            <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} flexBasis="30%" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px">
                                <Box component="h3" fontSize={{xs:20, md:25}} p={1} backgroundColor="#4771DF" flex={1} width="100%">就業規則</Box>
                                <Box p={1} backgroundColor="white">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/labour-management_01_kisoku.png"} alt="labour-management_01_kisoku" width="100%"></Box>
                                </Box>                                
                            </Stack>

                            <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} flexBasis="30%" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px">
                                <Box component="h3" fontSize={{xs:20, md:25}} p={1} backgroundColor="#4771DF" flex={1} width="100%">賃金規程</Box>
                                <Box p={1} backgroundColor="white">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/labour-management_02_Chingin.png"} alt="labour-management_02_Chingin" width="100%"></Box>
                                </Box>                                
                            </Stack>

                            <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} flexBasis="30%" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px">
                                <Box component="h3" fontSize={{xs:20, md:25}} p={1} backgroundColor="#4771DF" flex={1} width="100%">育児介護休業規程</Box>
                                <Box p={1} backgroundColor="white">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/labour-management_03_ikuji.png"} alt="labour-management_03_ikuji" width="100%" ></Box>
                                </Box>                                
                            </Stack>
                            
                        </Stack>

                        <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" gap={{xs:5,md:7}} mt={{xs:5,md:3}}  fontWeight="bold">

                            <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} flexBasis="30%" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px">
                                <Box component="h3" fontSize={{xs:20, md:25}} p={1} backgroundColor="#4771DF" flex={1} width="100%">雇用契約書</Box>
                                <Box p={1} backgroundColor="white">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/labour-management_04_koyou.png"} alt="labour-management_04_koyou" width="100%" ></Box>
                                </Box>                                
                            </Stack>

                            <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} flexBasis="30%" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px">
                                <Box component="h3" fontSize={{xs:20, md:25}} p={1} backgroundColor="#4771DF" flex={1} width="100%">36協定</Box>
                                <Box p={1} backgroundColor="white">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/labour-management_05_36.png"} alt="labour-management_05_36" width="100%" ></Box>
                                </Box>                                
                            </Stack>

                            <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} flexBasis="30%" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px">
                                <Box component="h3" fontSize={{xs:20, md:25}} p={1} backgroundColor="#4771DF" flex={1} width="100%">その他 労使協定</Box>
                                <Box p={1} backgroundColor="white">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/labour-management_06_others.png"} alt="labour-management_06_others" width="100%" ></Box>
                                </Box>                                
                            </Stack>

                        </Stack>

                        <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" gap={{xs:5,md:7}} mt={{xs:5,md:3}}  fontWeight="bold">

                            <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} flexBasis="30%" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px">
                                <Box component="h3" fontSize={{xs:20, md:25}} p={1} backgroundColor="#4771DF" flex={1} width="100%">賃金台帳</Box>
                                <Box p={1} backgroundColor="white">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/labour-management_07_daicho.png"} alt="labour-management_07_daicho" width="100%" ></Box>
                                </Box>                                
                            </Stack>

                            <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} flexBasis="30%" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px">
                                <Box component="h3" fontSize={{xs:20, md:25}} p={1} backgroundColor="#4771DF" flex={1} width="100%">出勤簿</Box>
                                <Box p={1} backgroundColor="white">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/labour-management_08_syukkin.png"} alt="labour-management_08_syukkin" width="100%" ></Box>
                                </Box>                                
                            </Stack>

                            <Stack alignItems="center" backgroundColor="#D1EAFE" borderRadius={3} flexBasis="30%" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px">
                                <Box component="h3" fontSize={{xs:20, md:25}} p={1} backgroundColor="#4771DF" flex={1} width="100%">労働者名簿</Box>
                                <Box p={1} backgroundColor="white">
                                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/labour-management_09_meibo.png"} alt="labour-management_09_meibo" width="100%"></Box>
                                </Box>                                
                            </Stack>

                        </Stack>
                    </Box>

                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" mt={10} textAlign="left" fontSize={{xs:18, md:20}} fontWeight="bold">

                        <Stack flexBasis="30%" gap={1}>
                            <Stack flexDirection="row" alignItems="center" sx={before_checkMark_blue}>すべての帳簿をお持ちですか？</Stack>
                            <Stack flexDirection="row" alignItems="center" sx={before_checkMark_blue}>受給要件に即していますか？</Stack>
                            <Stack flexDirection="row" alignItems="center" sx={before_checkMark_blue}>法改正に伴う対応をしていますか？</Stack>
                        </Stack>

                        <Stack  flexBasis="30%" gap={1} mt={{xs:1,md:0}}>
                            <Stack flexDirection="row" alignItems="center" sx={before_checkMark_blue}>各帳簿間で整合していますか？</Stack>
                            <Stack flexDirection="row" alignItems="center" sx={before_checkMark_blue}>労働法令に準じていますか？</Stack>
                        </Stack>
                        
                    </Stack>

                </Box>
            </Box>

            <Box component="section" backgroundColor="#D1EAFE" pb={10} textAlign="center">
                <Box className='section-content' >
                    
                    <Box margin="0 auto" mt={-1} width="30%" height={{xs:"50px",md:"100px"}} sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)",transform:"translateY(-1px)"}} backgroundColor="#EBEBEB" ></Box>
                    
                    <Box maxWidth={{xs:"80%",md:"30%"}} margin="0 auto" mt={10}>
                        <Box backgroundColor="white" p={2} borderRadius={5} textAlign="center" fontWeight="bold" fontSize={20}>助成金申請上だけでなく</Box>
                            
                        <Stack justifyContent="center" alignItems="center" position="relative" >
                            <Box width="30px" height="calc(60px / 2)" sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)"}} backgroundColor="white" position="absolute" bottom={-20}></Box>
                        </Stack>
                    </Box>

                    <Box component="h1" fontSize={{xs:20, md:30}} fontWeight="bold" mt={3}>そもそも<Box component="span" fontSize={{xs:30, md:45}} pl={1} pr={1}>労働法令上</Box>も労務帳簿の整備が必要な理由</Box>
                    <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={2} mb={2}></Box>

                    <Box component="h2" fontSize={{xs:20, md:20}} fontWeight="bold" mt={5}>労務帳簿の整備ができていないと、<Box component="span" color="#ED346C" ml={1} mr={1}>予期せぬ労使トラブル</Box>が起こり、会社として<Box component="span" color="#ED346C" ml={1} mr={1}>大きな損失</Box>となることも！</Box>
                    
                    <Box backgroundColor="white" p={{xs:2,md:10}} pt={{xs:10,md:10}} pb={{xs:10,md:10}} mt={10}>
                        <Box fontSize={{xs:20, md:20}} fontWeight="bold">ご存じですか？</Box>

                        <Stack alignItems="center">
                            <Box component="h2" mt={5} p={1} width={{xs:"100%",md:"70%"}} backgroundColor="#4771DF" color="white" fontSize={{xs:20, md:20}} fontWeight="bold" sx={{clipPath:"polygon(0 50%, 15px 0, calc(100% - 15px) 0, 100% 50%, calc(100% - 15px) 100%, 15px 100%)"}}>予期せぬ労使トラブルの実態</Box>
                        </Stack>
                        

                        <Box component="h2" fontSize={{xs:20, md:20}} fontWeight="bold" mt={5}>労基署の抜き打ち調査では、<Box component="span" color="#ED346C" fontSize={{xs:25, md:40}} ml={1} mr={1}>約７割</Box>の事業所に<Box component="span" color="#ED346C" fontSize={{xs:25, md:40}} ml={1} mr={1}>違反</Box>があった</Box>

                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/preparation_06.png"} alt="preparation_06" width="100%" maxWidth={300}></Box>

                        <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" alignItems="center" gap={3} mt={5}>

                            <Stack alignItems="center" backgroundColor="#EBEBEB" height={300} width={300} justifyContent="center" borderRadius={900} >
                                <Box component="h3" fontSize={{xs:18, md:20}} fontWeight="bold" backgroundColor="#4771DF" color="white" minWidth="80%" p={1}>定期監督等実施事業場数</Box>
                                <Box fontSize={{xs:20, md:25}} fontWeight="bold">年間約<Box component="span" color="#ED346C" fontSize={{xs:30, md:60}} ml={1} mr={1}>13</Box><Box component="span" color="#ED346C">万社</Box></Box>                                
                            </Stack>

                            <Stack alignItems="center" backgroundColor="#EBEBEB" height={300} width={300} justifyContent="center" borderRadius={900} >
                                <Box component="h3" fontSize={{xs:18, md:20}} fontWeight="bold" backgroundColor="#4771DF" color="white" minWidth="80%" p={1}>同違反事業場数</Box>
                                <Box fontSize={{xs:20, md:25}} fontWeight="bold">年間約<Box component="span" color="#ED346C" fontSize={{xs:30, md:60}} ml={1} mr={1}>9</Box><Box component="span" color="#ED346C">万社</Box></Box>                                
                            </Stack>

                            <Stack alignItems="center" backgroundColor="#EBEBEB" height={300} width={300} justifyContent="center" borderRadius={900} >
                                <Box component="h3" fontSize={{xs:18, md:20}} fontWeight="bold" backgroundColor="#4771DF" color="white" minWidth="80%" p={1}>同違反事業場比率</Box>
                                <Box fontSize={{xs:20, md:25}} fontWeight="bold">平均約<Box component="span" color="#ED346C" fontSize={{xs:30, md:60}} ml={1} mr={1}>70</Box><Box component="span" color="#ED346C">％</Box></Box>                                
                            </Stack>

                        </Stack>

                        <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" mt={10} textAlign="left" fontSize={{xs:18, md:20}} fontWeight="bold">

                            <Stack flexBasis="30%" gap={1}>
                                <Stack flexDirection="row" alignItems="center" sx={before_checkMark_blue}>労働時間に関する違反</Stack>
                                <Stack flexDirection="row" alignItems="center" sx={before_checkMark_blue}>安全基準に関する違反</Stack>
                                <Stack flexDirection="row" alignItems="center" sx={before_checkMark_blue}>割増賃金に関する違反</Stack>
                            </Stack>

                            <Stack  flexBasis="30%" gap={1} mt={{xs:1,md:0}}>
                                <Stack flexDirection="row" alignItems="center" sx={before_checkMark_blue}>労働条件の明示に関する違反</Stack>
                                <Stack flexDirection="row" alignItems="center" sx={before_checkMark_blue}>就業規則に関する違反</Stack>
                                <Stack flexDirection="row" alignItems="center" sx={before_checkMark_blue}>健康診断に関する違反</Stack>
                            </Stack>
                            
                        </Stack>
                    </Box>

                    <Box backgroundColor="white" p={{xs:2,md:10}} pt={{xs:10,md:10}} pb={{xs:10,md:10}} mt={10}>
                        
                        <Stack alignItems="center">
                            <Box component="h2" p={1} width={{xs:"100%",md:"70%"}} backgroundColor="#4771DF" color="white" fontSize={{xs:20, md:20}} fontWeight="bold" sx={{clipPath:"polygon(0 50%, 15px 0, calc(100% - 15px) 0, 100% 50%, calc(100% - 15px) 100%, 15px 100%)"}}>未払残業代での労使トラブル例</Box>
                        </Stack>

                        <Box component="h2" fontSize={{xs:20, md:20}} fontWeight="bold" mt={5}>未払残業代があったため、 従業員から<Box component="span" color="#ED346C" fontSize={{xs:25, md:40}} ml={1} mr={1}>過去４年分</Box>の残業代を請求された…</Box>

                        <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" alignItems="center" gap={3} mt={10}>

                            <Box flex={1} component="img" src={process.env.PUBLIC_URL+"/static/media/LP/preparation_07.png"} alt="preparation_07" width="100%" maxWidth={600}></Box>                            

                            <Box>
                                <Stack alignItems="center" backgroundColor="#EBEBEB" height={300} width={300} justifyContent="center" borderRadius={900} >
                                    <Box component="h3" fontSize={{xs:18, md:20}} fontWeight="bold" backgroundColor="#4771DF" color="white" minWidth="80%" p={1}>請求額</Box>
                                    <Box fontSize={{xs:20, md:25}} fontWeight="bold">約<Box component="span" color="#ED346C" fontSize={{xs:30, md:60}} ml={1} mr={1}>315</Box><Box component="span" color="#ED346C">万円</Box></Box>                                
                                </Stack>
                                <Box fontSize={12} mt={2}>※「従業員数10名」の「過去４年分」の残業手当を清算</Box>
                            </Box>

                        </Stack>

                       
                    </Box>

                    <Box margin="0 auto" mt={10} width="200px" height="50px" sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)"}} backgroundColor="#4771DF" ></Box>
                    
                    <Box fontSize={{xs:20, md:20}} fontWeight="bold" mt={10}>
                        <Box><Box component="span" color="#ED346C" mr={1}>助成金受給</Box>に向けた<Box component="span" color="#ED346C" ml={1} mr={1}>プロセス</Box>でしっかりと<Box component="span" color="#ED346C" ml={1} mr={1}>労務整備</Box>を行い、</Box>
                        <Box><Box component="span" color="#ED346C" mr={1}>労務リスク対策</Box>までしっかり行っておくことが重要です！</Box>
                    </Box>

                    
                </Box>
            </Box>

            {/* <Box component="section" backgroundColor="white" pb={10} pt={10} textAlign="center">
                <Box className='section-content' >
                    <Stack alignItems="center">
                        <Link href={"/"} target="_blank" backgroundColor="#4771DF" sx={{color:"white"}} fontWeight="bold" textAlign="center" mt={2} p={5} maxWidth={"95%"} width={400} borderRadius={2}>助成金コーディネートでできること</Link>
                    </Stack>
                </Box>
            </Box> */}

            <Box component="section" backgroundColor="#4771DF" textAlign="center" pt={10} pb={10}>
                <Box className='section-content' textAlign="center" color="white">

                    <Box fontSize={{xs:20, md:30}} fontWeight="bold">
                        <Box>会社の<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>労務リスク</Box>と、<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>助成金</Box>のお悩み、</Box>
                        <Box><Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>「助成金コーディネート」</Box>で<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>同時に解決</Box>しませんか？！</Box>
                    </Box>
                    
                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" mt={10} gap={5}>
                        
                        <Stack alignItems="center" gap={4}>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA1.png"} alt="CTA1" width="100%" maxWidth={300}></Box>

                            <Box width="100%">
                                <Box fontSize={16} color="white">たった<Box component="span" fontSize={30}>５</Box>分で<Box component="span" fontSize={30}>カンタン</Box>!</Box>
                                <ConversionButtonA display="block" fontSize={{xs:20, md:30}} >助成金診断<Box component="span" ml={1} fontSize={{xs:16, md:20}}>する</Box></ConversionButtonA>
                            </Box>
                        </Stack>
                        
                        
                        <Stack alignItems="center" gap={4}>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA2.png"} alt="CTA2" width="100%" maxWidth={300}></Box>
                            <Stack flex={1} justifyContent="end" width="100%">
                                
                                <ConversionButtonB display="block" fontSize={{xs:20, md:30}}><Box component="span"></Box>プロに相談<Box component="span" ml={1} fontSize={{xs:16, md:20}}>する</Box></ConversionButtonB>
                                
                            </Stack>
                        </Stack>
                        
                    </Stack>

                    <Box fontSize={{xs:20, md:30}} fontWeight="bold" mt={10}>まずは、<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>お気軽</Box>に、<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>お問合せ</Box>ください！</Box>
                </Box>
            </Box>

            <Footer/>
        </>
    )
}

export default Prepare;