import './App.css';
// import React, { useEffect } from 'react';
import { BrowserRouter,Routes,Route } from "react-router-dom";

import Top from "./Pages/LP/Top"
import LeagalCompliance from './Pages/LP/LegalCompliance';
import Customer1 from "./Pages/LP/Customer1"
import Customer2 from "./Pages/LP/Customer2"
import Customer3 from "./Pages/LP/Customer3"
import Faq from './Pages/LP/Faq';
import Flow from './Pages/LP/Flow';
import Price from './Pages/LP/Price';
import Prepare from './Pages/LP/Prepare';
import Service from './Pages/LP/Service';
import Company from './Pages/LP/Company';

function App() {

 

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path={"/legal-compliance"} element={<LeagalCompliance/>}/>
          <Route path={"/"} element={<Top/>}/>

          <Route path={"/prepare"} element={<Prepare/>}/>
          <Route path={"/faq"} element={<Faq/>}/>

          <Route path={"/flow"} element={<Flow/>}/>
          <Route path={"/price"} element={<Price/>}/>
          <Route path={"/service"} element={<Service/>}/>
          <Route path={"/company"} element={<Company/>}/>
          
          <Route path={"/customers/nicoichi"} element={<Customer1/>}/>
          <Route path={"/customers/criacao"} element={<Customer2/>}/>
          <Route path={"/customers/overcome-music"} element={<Customer3/>}/>

          
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
