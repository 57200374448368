import React from 'react';
import {Box,Stack} from '@mui/material'
import AccentBox from '../../Components/AccentBox';

function TopSection9(){

    

    return (
        <Box component="section" backgroundColor="white" pb={10}>
            <Box className='section-content' >
                <Box component="h1" textAlign="center" fontSize={{xs:20, md:30}} fontWeight="bold">しくみが一度できると、<Box component="br" display={{xs:"block",md:"none"}}/><Box component="span" fontSize={{xs:30, md:45}} mr={1}>助成金のフル活用は当たり前</Box>に！</Box>
                <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={2} mb={5}></Box>

                <Stack flexDirection={{sx:"column",md:"row"}} backgroundColor="#D1EAFE" borderRadius={5} p={2} gap={2}>
                    <Box backgroundColor="white" borderRadius={5}>
                        <img src={process.env.PUBLIC_URL+"/static/media/LP/TOP_joseikin3.png"} alt="TOP_joseikin3" style={{width:"100%",maxWidth:500}}></img>
                    </Box>
                    <Stack justifyContent="space-around">
                        <Box fontSize={{xs:16, md:20}} fontWeight="bold">
                            <Box >助成金は活用しないと、<AccentBox fontSize={{xs:20, md:25}} ml={1} mr={1}>もったいない</AccentBox>！</Box>
                            <Box >フル活用している会社はたったの<AccentBox fontSize={{xs:20, md:25}} ml={1} mr={1}>2割</AccentBox>？！</Box>
                        </Box>
                        <Box mt={2}>会社が支払っている雇用保険料を企業に還元してもらう制度が助成金です。<br/><Box component="span" fontWeight="bold" fontSize={{xs:16, md:20}} pr={1}>雇用保険料を支払っている</Box>のに、<Box component="span" fontWeight="bold" fontSize={{xs:16, md:20}} pl={1}>助成金を活用しないのはもったいない ！</Box></Box>
                        <Box mt={2}>雇用保険料を支払っている会社の中で、
実際に助成金を活用できている会社は、たったの<Box component="span" fontWeight="bold" fontSize={{xs:16, md:20}} pl={1}>「２割」</Box>。</Box>
                        <Box mt={2} fontSize={{xs:14, md:16}}><Box component="span" fontWeight="bold" fontSize={{xs:16, md:20}} pr={1}>「助成金のフル活用は当たり前！」</Box>そんな会社になるための準備を始めましょう。</Box>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    )
}

export default TopSection9;