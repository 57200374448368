import React from 'react';
import Header from "./Header"
import TopSection1 from './TopSection1';
import TopSection2 from './TopSection2';
import TopSection3 from './TopSection3';
import TopSection4 from './TopSection4';
import TopSection5 from './TopSection5';
import TopSection6 from './TopSection6';
import TopSection7 from './TopSection7';
import TopSection8 from './TopSection8';
import TopSection9 from './TopSection9';
import TopSection10 from './TopSection10';
import TopSection11 from './TopSection11';
import TopSection12 from './TopSection12';
import TopSection13 from './TopSection13';
import TopSection14 from './TopSection14';
import TopSection15 from './TopSection15';
import TopSection16 from './TopSection16';
import TopSection17 from './TopSection17';
import TopSection18 from './TopSection18';
import TopSection19 from './TopSection19';
import TopSection20 from './TopSection20';
import TopSection21 from './TopSection21';
// import LpSection22 from './LpSection22';
import TopSection23 from './TopSection23';
import EndSection from './EndSection';
import Footer from './Footer';


function Top() {

    return (
        <>
            <Header/>
            <TopSection1/>
            <TopSection2/>
            <TopSection3/>
            <TopSection4/>
            <TopSection5/>
            <TopSection6/>
            <TopSection7/>
            <TopSection8/>
            <TopSection9/>
            <TopSection10/>
            <TopSection11/>
            <TopSection12/>
            <TopSection13/>
            <TopSection14/>
            <TopSection15/>
            <TopSection16/>
            <TopSection17/>
            <TopSection18/>
            <TopSection19/>
            <TopSection20/>
            <TopSection21/>
            {/* <LpSection22/> */}
            {/* <TopSection23/> */}
            <EndSection/>
            <Footer/>
            
        </>
    );
}

export default Top;